import React, { Suspense } from "react";
import { Box } from "@mui/material";
import { adminStyle } from "./pageStyle";
import { AdminTopbar } from "./topbar";
import { Sidebar } from "./sidebar";
import { AdminLoginRouting, AdminRouting } from "src/routes";
import { AdminFooter } from "./footer";
import { FullScreenLoader } from "src/components/fullScreenLoader";
import { getRootScope } from "src/stores/shares/utility";

export const Layout = () => {
  const classes = adminStyle();
  let getRoot = getRootScope();

  return (
    <>
      {!getRoot?.user_id ? (
        <Suspense fallback={<FullScreenLoader loading={true} />}>
          <AdminLoginRouting />
        </Suspense>
      ) : (
        <>
          <AdminTopbar />
          <Sidebar />
          <Box
            component="main"
            className={classes.mainBoxContainer}
            sx={{ ml: "220px", mt: "60px" }}
          >
            <Suspense fallback={<FullScreenLoader loading={true} />}>
              <AdminRouting />
            </Suspense>
          </Box>
          <AdminFooter />
        </>
      )}
    </>
  );
};
