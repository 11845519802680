import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "src/stores";
import { AsyncResponseState, AsyncStateType, Headers } from "src/stores/shares/utility";
import { BlockUserResponse } from "./types";
import { envConfig } from "src/config/envConfig";
const headers = Headers();

// Define a type for the slice state
export interface BlockUserState extends AsyncResponseState<AxiosResponse<BlockUserResponse>> {}

// Define the initial state using that type
const initialState: BlockUserState = {
  currentState: AsyncStateType.INITIAL,
};

export const loadBlockUser = createAsyncThunk("blockUser/loadBlockUser", async (args, thunkAPI) => {
  return axios.post<BlockUserResponse>(
    `${envConfig?.remoteApi}/admin/blockusers`,
    {
      column_name: "dateDeleted",
      draw: 1,
      length: 25,
      order: "desc",
      search: "",
      start: 0,
    },
    { headers }
  );
});

export const BlockUserSlice = createSlice({
  name: "blockUser",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadBlockUser.pending, (state): BlockUserState => {
        return {
          ...state,
          currentState: AsyncStateType.PENDING,
        };
      })
      .addCase(loadBlockUser.fulfilled, (state, action): BlockUserState => {
        return {
          ...state,
          currentState: AsyncStateType.FULFILLED,
          response: action.payload,
        };
      })
      .addCase(loadBlockUser.rejected, (state, action): BlockUserState => {
        return {
          ...state,
          currentState: AsyncStateType.REJECTED,
          error: `error: ${action.error.code} ${action.error.message}`,
        };
      });
  },
});

// export the combined reducers
export const BlockUserReducer = BlockUserSlice.reducer;

// Other code such as selectors can use the imported `RootState` type
export const selectBlockUser = (state: RootState) => state.blockUserReducer;
