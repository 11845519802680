import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "src/stores";
import { AsyncResponseState, AsyncStateType, Headers } from "src/stores/shares/utility";
import { UserDetailResponse, UserResponse, UserType } from "./types";
import { envConfig } from "src/config/envConfig";
const headers = Headers();

export interface EventContactState {
  getUser: AsyncResponseState<AxiosResponse<UserResponse>>;
  addUser: AsyncResponseState<AxiosResponse<UserResponse>>;
  detailUser: AsyncResponseState<AxiosResponse<UserDetailResponse>>;
  editUser: AsyncResponseState<AxiosResponse<UserResponse>>;
  deleteUser: AsyncResponseState<AxiosResponse<UserResponse>>;
  detailUserEvent: AsyncResponseState<AxiosResponse<UserDetailResponse>>;
}

// Define the initial state using that type

const initialState: EventContactState = {
  getUser: {
    currentState: AsyncStateType.INITIAL,
  },
  addUser: {
    currentState: AsyncStateType.INITIAL,
  },
  detailUser: {
    currentState: AsyncStateType.INITIAL,
  },
    editUser: {
      currentState: AsyncStateType.INITIAL,
    },
  deleteUser: {
    currentState: AsyncStateType.INITIAL,
  },
  detailUserEvent: {
    currentState: AsyncStateType.INITIAL,
  },
};

export const loadGetUser = createAsyncThunk("getUser/loadGetUser", async (thunkAPI) => {
  return axios.get<UserResponse>(`${envConfig?.remoteApi}/admin/getUserDataList`, {
    headers,
  });
});

export const loadAddUser = createAsyncThunk(
  "addUser/loadAddUser",
  async (args: FormData | UserType, thunkAPI) => {
    return axios.post<UserResponse>(`${envConfig?.remoteApi}/admin/createUser`, args, {
      headers: {
        ...headers,
        "Content-Type": "multipart/form-data",
      },
      data: args,
    });
  }
);

export const loadUserDelete = createAsyncThunk(
  "deleteUser/loadUserDelete",
  async (args: number, thunkAPI) => {
    return axios.delete<UserResponse>(
      `${envConfig?.remoteApi}/admin/deleteUser/${args}`,
      {
        headers,
      }
    );
  }
);

export const loadUserDetail = createAsyncThunk(
  "detailUser/loadUserDetail",
  async (args: number, thunkAPI) => {
    return axios.get<UserDetailResponse>(
      `${envConfig?.remoteApi}/admin/getUser/${args}`,
      {
        headers,
      }
    );
  }
);

export const loadUserEventDetail = createAsyncThunk(
  "detailUserEvent/loadUserEventDetail",
  async (args: number, thunkAPI) => {
    return axios.get<UserDetailResponse>(
      `${envConfig?.remoteApi}/admin/likeEventList?user_id=${args}`,
      {
        headers,
      }
    );
  }
);

export const loadEditUser = createAsyncThunk(
  "editUser/loadEditUser",
  async (args: FormData | UserType, thunkAPI) => {
    return axios.post<UserResponse>(`${envConfig?.remoteApi}/admin/updateUser`, args, {
      headers: {
        ...headers,
        "Content-Type": "multipart/form-data",
      },
      data: args,
    });
  }
);


export const UserSlice = createSlice({
  name: "User",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // loadGetUser
      .addCase(loadGetUser.pending, (state): EventContactState => {
        return {
          ...state,
          getUser: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadGetUser.fulfilled, (state, action): EventContactState => {
        return {
          ...state,
          getUser: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadGetUser.rejected, (state, action): EventContactState => {
        return {
          ...state,
          getUser: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
    // loadUserDetail
    .addCase(loadUserDetail.pending, (state): EventContactState => {
      return {
        ...state,
        detailUser: {
          currentState: AsyncStateType.PENDING,
        },
      };
    })
    .addCase(loadUserDetail.fulfilled, (state, action): EventContactState => {
      return {
        ...state,
        detailUser: {
          currentState: AsyncStateType.FULFILLED,
          response: action.payload,
        },
      };
    })
    .addCase(loadUserDetail.rejected, (state, action): EventContactState => {
      return {
        ...state,
        detailUser: {
          currentState: AsyncStateType.REJECTED,
          error: `error: ${action.error.code} ${action.error.message}`,
        },
      };
    })
    // loadUserEventDetail
    .addCase(loadUserEventDetail.pending, (state): EventContactState => {
      return {
        ...state,
        detailUserEvent: {
          currentState: AsyncStateType.PENDING,
        },
      };
    })
    .addCase(loadUserEventDetail.fulfilled, (state, action): EventContactState => {
      return {
        ...state,
        detailUserEvent: {
          currentState: AsyncStateType.FULFILLED,
          response: action.payload,
        },
      };
    })
    .addCase(loadUserEventDetail.rejected, (state, action): EventContactState => {
      return {
        ...state,
        detailUserEvent: {
          currentState: AsyncStateType.REJECTED,
          error: `error: ${action.error.code} ${action.error.message}`,
        },
      };
    })
    // loadUserDelete
    .addCase(loadUserDelete.pending, (state): EventContactState => {
      return {
        ...state,
        deleteUser: {
          currentState: AsyncStateType.PENDING,
        },
      };
    })
    .addCase(loadUserDelete.fulfilled, (state, action): EventContactState => {
      return {
        ...state,
        deleteUser: {
          currentState: AsyncStateType.FULFILLED,
          response: action.payload,
        },
      };
    })
    .addCase(loadUserDelete.rejected, (state, action): EventContactState => {
      return {
        ...state,
        deleteUser: {
          currentState: AsyncStateType.REJECTED,
          error: `error: ${action.error.code} ${action.error.message}`,
        },
      };
    })
    // loadEditEventContact
    .addCase(loadEditUser.pending, (state): EventContactState => {
      return {
        ...state,
        editUser: {
          currentState: AsyncStateType.PENDING,
        },
      };
    })
    .addCase(loadEditUser.fulfilled, (state, action): EventContactState => {
      return {
        ...state,
        editUser: {
          currentState: AsyncStateType.FULFILLED,
          // response: action.payload,
        },
      };
    })
    .addCase(loadEditUser.rejected, (state, action): EventContactState => {
      return {
        ...state,
        editUser: {
          currentState: AsyncStateType.REJECTED,
          error: `error: ${action.error.code} ${action.error.message}`,
        },
      };
    })
  },
});

// export the combined reducers
export const UserReducer = UserSlice.reducer;

// Other code such as selectors can use the imported `RootState` type
export const selectUser = (state: RootState) => state.userReducer;
