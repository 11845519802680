import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "src/stores";
import { AsyncResponseState, AsyncStateType, Headers } from "src/stores/shares/utility";
import { CurrentAssignResponse } from "./types";
import { envConfig } from "src/config/envConfig";
const headers = Headers();

// Define a type for the slice state
export interface CurrentAssignState {
  getCurrentAssign: AsyncResponseState<AxiosResponse<CurrentAssignResponse>>;
  getUpcomingDates: AsyncResponseState<AxiosResponse<CurrentAssignResponse>>;
}

// Define the initial state using that type
const initialState: CurrentAssignState = {
  getCurrentAssign: {
    currentState: AsyncStateType.INITIAL,
  },
  getUpcomingDates: {
    currentState: AsyncStateType.INITIAL,
  },
};

export const loadCurrentAssign = createAsyncThunk(
  "currentAssign/loadCurrentAssign",
  async (args, thunkAPI) => {
    return axios.post<CurrentAssignResponse>(
      `${envConfig?.remoteApi}/admin/CurrentAssign`,
      {
        column_name: "to_lastLogin",
        draw: 1,
        length: 10,
        order: "desc",
        search: "",
        start: 0,
      },
      { headers }
    );
  }
);

export const loadUpcomingDates = createAsyncThunk(
  "upcomingDates/loadUpcomingDates",
  async (args, thunkAPI) => {
    return axios.post<CurrentAssignResponse>(
      `${envConfig?.remoteApi}/admin/UpcomingDates`,
      {
        column_name: "fname",
        draw: 1,
        length: 10,
        order: "asc",
        search: "",
        start: 0,
        matchmaker_id: "1",
        role_id: "1",
      },
      { headers }
    );
  }
);

export const CurrentAssignSlice = createSlice({
  name: "currentAssign",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // loadCurrentAssign
      .addCase(loadCurrentAssign.pending, (state): CurrentAssignState => {
        return {
          ...state,
          getCurrentAssign: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadCurrentAssign.fulfilled, (state, action): CurrentAssignState => {
        return {
          ...state,
          getCurrentAssign: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadCurrentAssign.rejected, (state, action): CurrentAssignState => {
        return {
          ...state,
          getCurrentAssign: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadUpcomingDates
      .addCase(loadUpcomingDates.pending, (state): CurrentAssignState => {
        return {
          ...state,
          getUpcomingDates: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadUpcomingDates.fulfilled, (state, action): CurrentAssignState => {
        return {
          ...state,
          getUpcomingDates: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadUpcomingDates.rejected, (state, action): CurrentAssignState => {
        return {
          ...state,
          getUpcomingDates: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      });
  },
});

// export the combined reducers
export const CurrentAssignReducer = CurrentAssignSlice.reducer;

// Other code such as selectors can use the imported `RootState` type
export const selectCurrentAssign = (state: RootState) => state.currentAssignReducer;
