import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "src/stores";
import { AsyncResponseState, AsyncStateType, Headers } from "src/stores/shares/utility";
import { TipListResponse } from "./types";
import { envConfig } from "src/config/envConfig";
const headers = Headers();

// Define a type for the slice state
export interface TipListState {
  getTipUserList: AsyncResponseState<AxiosResponse<TipListResponse>>;
  deleteTipUser: AsyncResponseState<AxiosResponse<TipListResponse>>;
}

// Define the initial state using that type
const initialState: TipListState = {
  getTipUserList: {
    currentState: AsyncStateType.INITIAL,
  },
  deleteTipUser: {
    currentState: AsyncStateType.INITIAL,
  },
};

export const loadTipList = createAsyncThunk("tipList/loadTipList", async (args, thunkAPI) => {
  return axios.post<TipListResponse>(
    `${envConfig?.remoteApi}/admin/tipslist`,
    {
      column_name: "single_name",
      draw: 1,
      length: 25,
      order: "asc",
      search: "",
      start: 0,
    },
    { headers }
  );
});

export const loadDeleteTipUser = createAsyncThunk(
  "tipList/loadDeleteTipUser",
  async (args: number, thunkAPI) => {
    return axios.post<TipListResponse>(
      `${envConfig?.remoteApi}/admin/tipsDelete`,
      {
        id: args,
      },
      { headers }
    );
  }
);

export const TipListSlice = createSlice({
  name: "tipList",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // loadTipList
      .addCase(loadTipList.pending, (state): TipListState => {
        return {
          ...state,
          getTipUserList: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadTipList.fulfilled, (state, action): TipListState => {
        return {
          ...state,
          getTipUserList: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadTipList.rejected, (state, action): TipListState => {
        return {
          ...state,
          getTipUserList: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadDeleteTipUser
      .addCase(loadDeleteTipUser.pending, (state): TipListState => {
        return {
          ...state,
          deleteTipUser: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadDeleteTipUser.fulfilled, (state, action): TipListState => {
        return {
          ...state,
          deleteTipUser: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadDeleteTipUser.rejected, (state, action): TipListState => {
        return {
          ...state,
          deleteTipUser: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      });
  },
});

// export the combined reducers
export const TipListReducer = TipListSlice.reducer;

// Other code such as selectors can use the imported `RootState` type
export const selectTipList = (state: RootState) => state.tipListReducer;
