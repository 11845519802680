import { createAction } from "@reduxjs/toolkit";
import { envConfig } from "src/config/envConfig";

function withPayloadType<T>() {
  return (t: T) => ({ payload: t });
}

export const createActionWithPayload = <T>(action: string) =>
  createAction(action, withPayloadType<T>());

export { createAction };

export enum AsyncStateType {
  INITIAL,
  PENDING,
  FULFILLED,
  REJECTED,
}
export interface AsyncResponseState<T> {
  currentState: AsyncStateType;
  error?: string;
  response?: T;
}

export const getBearerToken = () => {
  return localStorage.getItem(envConfig?.localStorageKey) || "";
};

export const setBearerToken = (token: string) => {
  localStorage.setItem(envConfig?.localStorageKey, token);
};

export const clearBearerToken = () => {
  localStorage.removeItem(envConfig?.localStorageKey);
};

export const getUserRole = () => {
  return localStorage.getItem('userRole') || "";
};

export const setUserRole = (roleId: string) => {
  localStorage.setItem('userRole', roleId);
};

export const clearUserRole = () => {
  localStorage.removeItem('userRole');
};

export const getUserId = () => {
  return localStorage.getItem('userId') || "";
};

export const setUserId = (userId: string) => {
  localStorage.setItem('userId', userId);
};

export const clearUserId = () => {
  localStorage.removeItem('userId');
};
const cookies = document.cookie.split("admindata=");

export const getRefreshToken = () => {
  const getToken = cookies[1] && JSON.parse(cookies[1]);
  return getToken?.refreshToken || "";
};

export const getAccessToken = () => {
  const getToken = cookies[1] && JSON.parse(cookies[1]);
  return getToken?.accessToken || "";
};

export const getRootScope = () => {
  const getToken = cookies[1] && JSON.parse(cookies[1]);
  return getToken?.rootScope || "";
};

export const Headers = () => {
  const token = getBearerToken();
  return { Authorization: `Bearer ${token}` };
};
