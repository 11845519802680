import { Box, CssBaseline, Typography } from "@mui/material";
import { footerStyle } from "./pageStyle";

export const AdminFooter = () => {
  const classes = footerStyle();
  return (
    <Box className={classes.footerBox}>
      <CssBaseline />
      <Typography fontWeight="700" fontSize="13px" color="#676a6c" mr={1}>
        opphavsrett
      </Typography>
      Fast Folge © 2017
    </Box>
  );
};
