import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "src/stores";
import { AsyncResponseState, AsyncStateType, Headers } from "src/stores/shares/utility";
import { ReviewDetailResponse } from "./types";
import { envConfig } from "src/config/envConfig";
import { UserAndCurrentUserType } from "src/pages/paged-response";
const headers = Headers();

// Define a type for the slice state
export interface ReviewDetailState
  extends AsyncResponseState<AxiosResponse<ReviewDetailResponse>> {}

// Define the initial state using that type
const initialState: ReviewDetailState = {
  currentState: AsyncStateType.INITIAL,
};

export const loadReviewDetail = createAsyncThunk(
  "reviewDetail/loadReviewDetail",
  async (UserAndCurrentUser: UserAndCurrentUserType, thunkAPI) => {
    return axios.post<ReviewDetailResponse>(
      `${envConfig?.remoteApi}/admin2/GetReview`,
      {
        assined_id: UserAndCurrentUser?.current_assign_user,
        user_id: UserAndCurrentUser?.user_id,
      },
      { headers }
    );
  }
);

export const ReviewDetailSlice = createSlice({
  name: "ReviewDetail",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadReviewDetail.pending, (state): ReviewDetailState => {
        return {
          ...state,
          currentState: AsyncStateType.PENDING,
        };
      })
      .addCase(loadReviewDetail.fulfilled, (state, action): ReviewDetailState => {
        return {
          ...state,
          currentState: AsyncStateType.FULFILLED,
          response: action.payload,
        };
      })
      .addCase(loadReviewDetail.rejected, (state, action): ReviewDetailState => {
        return {
          ...state,
          currentState: AsyncStateType.REJECTED,
          error: `error: ${action.error.code} ${action.error.message}`,
        };
      });
  },
});

// export the combined reducers
export const ReviewDetailReducer = ReviewDetailSlice.reducer;

// Other code such as selectors can use the imported `RootState` type
export const selectReviewDetail = (state: RootState) => state.reviewDetailReducer;
