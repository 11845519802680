export const envConfig = {
  environment: process.env.NODE_ENV,
  remoteApi: process.env.REACT_APP_REMOTE_API,
  firebasePassword: process.env.REACT_APP_FIREBASE_PASSWORD,
  localStorageKey: "https://fastfolge.com:state",
  profileURL: process.env.REACT_APP_PROFILE_URL,
  gMapApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  gPlaceApiKey: process.env.REACT_APP_GOOGLE_PLACE_API,
  stripe_key: process.env.REACT_APP_STRIPE_KEY,
};
