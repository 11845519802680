import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "src/stores";
import { AsyncResponseState, AsyncStateType, Headers } from "src/stores/shares/utility";
import { ProfileDetailResponse } from "./types";
import { envConfig } from "src/config/envConfig";
const headers = Headers();

// Define a type for the slice state
export interface ProfileDetailState
  extends AsyncResponseState<AxiosResponse<ProfileDetailResponse>> {}

// Define the initial state using that type
const initialState: ProfileDetailState = {
  currentState: AsyncStateType.INITIAL,
};

export const loadProfileDetail = createAsyncThunk(
  "profileDetail/loadProfileDetail",
  async (user_id: string, thunkAPI) => {
    return axios.get<ProfileDetailResponse>(
      `${envConfig?.remoteApi}/home/profileview?user_id=${user_id}`,
      { headers }
    );
  }
);

export const ProfileDetailSlice = createSlice({
  name: "profileDetail",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadProfileDetail.pending, (state): ProfileDetailState => {
        return {
          ...state,
          currentState: AsyncStateType.PENDING,
        };
      })
      .addCase(loadProfileDetail.fulfilled, (state, action): ProfileDetailState => {
        return {
          ...state,
          currentState: AsyncStateType.FULFILLED,
          response: action.payload,
        };
      })
      .addCase(loadProfileDetail.rejected, (state, action): ProfileDetailState => {
        return {
          ...state,
          currentState: AsyncStateType.REJECTED,
          error: `error: ${action.error.code} ${action.error.message}`,
        };
      });
  },
});

// export the combined reducers
export const ProfileDetailReducer = ProfileDetailSlice.reducer;

// Other code such as selectors can use the imported `RootState` type
export const selectProfileDetail = (state: RootState) => state.profileDetailReducer;
