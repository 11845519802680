import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "src/stores";
import { AsyncResponseState, AsyncStateType, Headers } from "src/stores/shares/utility";
import { MatchingUsersResponse } from "./types";
import { envConfig } from "src/config/envConfig";
const headers = Headers();

// Define a type for the slice state
export interface MatchingUsersState
  extends AsyncResponseState<AxiosResponse<MatchingUsersResponse>> {}

// Define the initial state using that type
const initialState: MatchingUsersState = {
  currentState: AsyncStateType.INITIAL,
};

export const loadMatchingUsers = createAsyncThunk(
  "matchingUsers/loadMatchingUsers",
  async (userId: string, thunkAPI) => {
    return axios.post<MatchingUsersResponse>(
      `${envConfig?.remoteApi}/admin2/matchingUsers`,
      {
        user_id: userId,
      },
      { headers }
    );
  }
);

export const MatchingUsersSlice = createSlice({
  name: "matchingUsers",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadMatchingUsers.pending, (state): MatchingUsersState => {
        return {
          ...state,
          currentState: AsyncStateType.PENDING,
        };
      })
      .addCase(loadMatchingUsers.fulfilled, (state, action): MatchingUsersState => {
        return {
          ...state,
          currentState: AsyncStateType.FULFILLED,
          response: action.payload,
        };
      })
      .addCase(loadMatchingUsers.rejected, (state, action): MatchingUsersState => {
        return {
          ...state,
          currentState: AsyncStateType.REJECTED,
          error: `error: ${action.error.code} ${action.error.message}`,
        };
      });
  },
});

// export the combined reducers
export const MatchingUsersReducer = MatchingUsersSlice.reducer;

// Other code such as selectors can use the imported `RootState` type
export const selectMatchingUsers = (state: RootState) => state.matchingUsersReducer;
