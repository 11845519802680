import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "src/stores";
import { AsyncResponseState, AsyncStateType, Headers } from "src/stores/shares/utility";
import { TicketDetailResponse, TicketResponse } from "./types";
import { envConfig } from "src/config/envConfig";
const headers = Headers();

export interface TicketState {
  getTicket: AsyncResponseState<AxiosResponse<TicketResponse>>;
  detailTicket: AsyncResponseState<AxiosResponse<TicketDetailResponse>>;
  resendTicket: AsyncResponseState<AxiosResponse<TicketResponse>>;
  validateTicket: AsyncResponseState<AxiosResponse<TicketResponse>>;
}

// Define the initial state using that type

const initialState: TicketState = {
  getTicket: {
    currentState: AsyncStateType.INITIAL,
  },
  detailTicket: {
    currentState: AsyncStateType.INITIAL,
  },
  resendTicket: {
    currentState: AsyncStateType.INITIAL,
  },
  validateTicket: {
    currentState: AsyncStateType.INITIAL,
  },
};

export const loadGetTicket = createAsyncThunk(
  "getTicket/loadGetTicket",
  async (args: { user_id?: string }, thunkAPI) => {
    const params = args.user_id ? `?user_id=${args.user_id}` : "";
    return axios.get<TicketResponse>(`${envConfig?.remoteApi}/admin/ticketList${params}`, {
      headers,
    });
  }
);

export const loadTicketDetail = createAsyncThunk(
  "detailTicket/loadTicketDetail",
  async (args: number, thunkAPI) => {
    return axios.get<TicketDetailResponse>(
      `${envConfig?.remoteApi}/admin/getTicketDetails/${args}`,
      {
        headers,
      }
    );
  }
);

export const loadResendTicket = createAsyncThunk(
  "resendTicket/loadResendTicket",
  async (args: number, thunkAPI) => {
    return axios.get<TicketResponse>(`${envConfig?.remoteApi}/admin/resendEventTicket/${args}`, {
      headers,
    });
  }
);

export const loadValidateTicket = createAsyncThunk(
  "validateTicket/loadValidateTicket",
  async (args: { event_id?: string; user_id?: string; guest_user_id?: string }, thunkAPI) => {
    console.log("eventId", args.event_id);
    console.log("userId", args.user_id);
    console.log("guestUserId", args.guest_user_id);
    let params:any;
    if(args.event_id && args.user_id){
      params = `?event_id=${args.event_id}&user_id=${args.user_id}`;
    }else if(args.event_id && args.guest_user_id){
      params = `?event_id=${args.event_id}&guest_user_id=${args.guest_user_id}`;
    }
    return axios.get<TicketResponse>(`${envConfig?.remoteApi}/user/eventIsAttended${params}`, {
      headers,
    });
  }
);

export const TicketSlice = createSlice({
  name: "Ticket",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // loadGetTicket
      .addCase(loadGetTicket.pending, (state): TicketState => {
        return {
          ...state,
          getTicket: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadGetTicket.fulfilled, (state, action): TicketState => {
        return {
          ...state,
          getTicket: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadGetTicket.rejected, (state, action): TicketState => {
        return {
          ...state,
          getTicket: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      //loadTicketDetail
      .addCase(loadTicketDetail.pending, (state): TicketState => {
        return {
          ...state,
          detailTicket: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadTicketDetail.fulfilled, (state, action): TicketState => {
        return {
          ...state,
          detailTicket: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadTicketDetail.rejected, (state, action): TicketState => {
        return {
          ...state,
          detailTicket: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      //resend Ticekt
      .addCase(loadResendTicket.pending, (state): TicketState => {
        return {
          ...state,
          resendTicket: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadResendTicket.fulfilled, (state, action): TicketState => {
        return {
          ...state,
          resendTicket: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadResendTicket.rejected, (state, action): TicketState => {
        return {
          ...state,
          resendTicket: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadValidateTicket
      .addCase(loadValidateTicket.pending, (state): TicketState => {
        return {
          ...state,
          validateTicket: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadValidateTicket.fulfilled, (state, action): TicketState => {
        return {
          ...state,
          validateTicket: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadValidateTicket.rejected, (state, action): TicketState => {
        return {
          ...state,
          validateTicket: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      });
  },
});

// export the combined reducers
export const TicketReducer = TicketSlice.reducer;

// Other code such as selectors can use the imported `RootState` type
export const selectTicket = (state: RootState) => state.ticketReducer;
