import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "src/stores";
import { AsyncResponseState, AsyncStateType, Headers } from "src/stores/shares/utility";
import { OnlineUserArgs, OnlineUserResponse } from "./types";
import { envConfig } from "src/config/envConfig";
const headers = Headers();

// Define a type for the slice state
export interface OnlineUserState extends AsyncResponseState<AxiosResponse<OnlineUserResponse>> {}

// Define the initial state using that type
const initialState: OnlineUserState = {
  currentState: AsyncStateType.INITIAL,
};

export const loadOnlineUser = createAsyncThunk(
  "onlineUser/loadOnlineUser",
  async (args: OnlineUserArgs, thunkAPI) => {
    return axios.post<OnlineUserResponse>(
      `${envConfig?.remoteApi}/admin/OnlineUsers`,
      {
        matchmaker_id: args?.matchmaker_id,
        role_id: args?.role_id,
      },
      { headers }
    );
  }
);

export const OnlineUserSlice = createSlice({
  name: "onlineUser",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadOnlineUser.pending, (state): OnlineUserState => {
        return {
          ...state,
          currentState: AsyncStateType.PENDING,
        };
      })
      .addCase(loadOnlineUser.fulfilled, (state, action): OnlineUserState => {
        return {
          ...state,
          currentState: AsyncStateType.FULFILLED,
          response: action.payload,
        };
      })
      .addCase(loadOnlineUser.rejected, (state, action): OnlineUserState => {
        return {
          ...state,
          currentState: AsyncStateType.REJECTED,
          error: `error: ${action.error.code} ${action.error.message}`,
        };
      });
  },
});

// export the combined reducers
export const OnlineUserReducer = OnlineUserSlice.reducer;

// Other code such as selectors can use the imported `RootState` type
export const selectOnlineUser = (state: RootState) => state.onlineUserReducer;
