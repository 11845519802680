import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "src/stores";
import { AsyncResponseState, AsyncStateType } from "src/stores/shares/utility";
import { FirebaseResponse, FirebaseType, LoginResponse, LoginType } from "./types";
import { envConfig } from "src/config/envConfig";

// Define a type for the slice state
export interface LoginState {
  loginUser: AsyncResponseState<AxiosResponse<LoginResponse>>;
  firebaseUpdate: AsyncResponseState<AxiosResponse<FirebaseResponse>>;
  forgotPassword: AsyncResponseState<AxiosResponse<LoginResponse>>;
}

// Define the initial state using that type
const initialState: LoginState = {
  loginUser: {
    currentState: AsyncStateType.INITIAL,
  },
  firebaseUpdate: {
    currentState: AsyncStateType.INITIAL,
  },
  forgotPassword: {
    currentState: AsyncStateType.INITIAL,
  },
};

export const loadLogin = createAsyncThunk(
  "loginUser/loadLogin",
  async (loginData: LoginType, thunkAPI) => {
    return axios.post<LoginResponse>(`${envConfig?.remoteApi}/admin/adminlogin`, loginData);
  }
);

export const loadFirebaseUpdate = createAsyncThunk(
  "firebaseUpdate/loadFirebaseUpdate",
  async (firebaseData: FirebaseType, thunkAPI) => {
    return axios.post<FirebaseResponse>(
      `${envConfig?.remoteApi}/admin/UpdateFirebaseID`,
      firebaseData
    );
  }
);

export const loadForgetPass = createAsyncThunk(
  "loginUser/loadForgetPass",
  async (email: string, thunkAPI) => {
    return axios.post<LoginResponse>(`${envConfig?.remoteApi}/admin/forgot_password`, {
      email: email,
    });
  }
);

export const LoginSlice = createSlice({
  name: "loginUser",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // loadLogin
      .addCase(loadLogin.pending, (state): LoginState => {
        return {
          ...state,
          loginUser: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadLogin.fulfilled, (state, action): LoginState => {
        return {
          ...state,
          loginUser: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadLogin.rejected, (state, action): LoginState => {
        return {
          ...state,
          loginUser: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadFirebaseUpdate
      .addCase(loadFirebaseUpdate.pending, (state): LoginState => {
        return {
          ...state,
          firebaseUpdate: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadFirebaseUpdate.fulfilled, (state, action): LoginState => {
        return {
          ...state,
          firebaseUpdate: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadFirebaseUpdate.rejected, (state, action): LoginState => {
        return {
          ...state,
          firebaseUpdate: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      });
  },
});

// export the combined reducers
export const LoginReducer = LoginSlice.reducer;

// Other code such as selectors can use the imported `RootState` type
export const selectLogin = (state: RootState) => state.loginReducer;
