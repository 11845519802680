import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "src/stores";
import { AsyncResponseState, AsyncStateType, Headers } from "src/stores/shares/utility";
import { ActivityListResponse, GetUsersListResponse } from "./types";
import { envConfig } from "src/config/envConfig";
const headers = Headers();

// Define a type for the slice state
export interface ActivityListState {
  getUsersList: AsyncResponseState<AxiosResponse<GetUsersListResponse>>;
  activityList: AsyncResponseState<AxiosResponse<ActivityListResponse>>;
}

// Define the initial state using that type
const initialState: ActivityListState = {
  getUsersList: {
    currentState: AsyncStateType.INITIAL,
  },
  activityList: {
    currentState: AsyncStateType.INITIAL,
  },
};

export const loadGetUsersList = createAsyncThunk(
  "getUsersList/loadGetUsersList",
  async (args, thunkAPI) => {
    return axios.post<GetUsersListResponse>(
      `${envConfig?.remoteApi}/admin/getUsersList`,
      {
        length: 25,
        start: 0,
        draw: 1,
        column_name: "user_id",
        order: "asc",
        search: "",
        selectedType: "November",
        plan: "",
      },
      { headers }
    );
  }
);

export const loadActivityList = createAsyncThunk(
  "activityList/loadActivityList",
  async (userId: string, thunkAPI) => {
    return axios.post<ActivityListResponse>(
      `${envConfig?.remoteApi}/admin/activityList`,
      {
        length: 25,
        start: 0,
        draw: 1,
        column_name: "log_time",
        order: "desc",
        search: "",
        selectedUser: userId,
      },
      { headers }
    );
  }
);

export const ActivityListSlice = createSlice({
  name: "activityList",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // getUsersList
      .addCase(loadGetUsersList.pending, (state): ActivityListState => {
        return {
          ...state,
          getUsersList: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadGetUsersList.fulfilled, (state, action): ActivityListState => {
        return {
          ...state,
          getUsersList: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadGetUsersList.rejected, (state, action): ActivityListState => {
        return {
          ...state,
          getUsersList: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // activityList
      .addCase(loadActivityList.pending, (state): ActivityListState => {
        return {
          ...state,
          activityList: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadActivityList.fulfilled, (state, action): ActivityListState => {
        return {
          ...state,
          activityList: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadActivityList.rejected, (state, action): ActivityListState => {
        return {
          ...state,
          activityList: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      });
  },
});

// export the combined reducers
export const ActivityListReducer = ActivityListSlice.reducer;

// Other code such as selectors can use the imported `RootState` type
export const selectActivityList = (state: RootState) => state.activityListReducer;
