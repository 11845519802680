import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "src/stores";
import { AsyncResponseState, AsyncStateType, Headers } from "src/stores/shares/utility";
import { ProfilePictureResponse, UploadProfilePictureResponse } from "./types";
import { envConfig } from "src/config/envConfig";
const headers = Headers();

// Define a type for the slice state
export interface ProfilePictureState {
  getProfilePicture: AsyncResponseState<AxiosResponse<ProfilePictureResponse>>;
  uploadProfilePicture: AsyncResponseState<AxiosResponse<UploadProfilePictureResponse>>;
  getUploadedProfilePicture: AsyncResponseState<AxiosResponse<UploadProfilePictureResponse>>;
  makeProfilePicture: AsyncResponseState<AxiosResponse<UploadProfilePictureResponse>>;
}

// Define the initial state using that type
const initialState: ProfilePictureState = {
  getProfilePicture: {
    currentState: AsyncStateType.INITIAL,
  },
  uploadProfilePicture: {
    currentState: AsyncStateType.INITIAL,
  },
  getUploadedProfilePicture: {
    currentState: AsyncStateType.INITIAL,
  },
  makeProfilePicture: {
    currentState: AsyncStateType.INITIAL,
  },
};

export const loadGetProfilePicture = createAsyncThunk(
  "ProfilePicture/loadGetProfilePicture",
  async (args: string, thunkAPI) => {
    return axios.post<ProfilePictureResponse>(
      `${envConfig?.remoteApi}/profilepicture/GetUserPhotos`,
      { user_id: args },
      { headers }
    );
  }
);

export const loadGetUploadedProfilePicture = createAsyncThunk(
  "ProfilePicture/loadGetUploadedProfilePicture",
  async (args: { user_id: string; imgposition: number }, thunkAPI) => {
    return axios.post<UploadProfilePictureResponse>(
      `${envConfig?.remoteApi}/profilepicture/GetUploadedImage`,
      args,
      { headers }
    );
  }
);

export const loadUploadProfilePicture = createAsyncThunk(
  "ProfilePicture/loadUploadProfilePicture",
  async (args: FormData, thunkAPI) => {
    return axios.post<UploadProfilePictureResponse>(
      `${envConfig?.remoteApi}/profilepicture/UploudImage`,
      args,
      {
        headers: {
          ...headers,
          "Content-Type": "multipart/form-data",
        },
        data: args,
      }
    );
  }
);

export const loadMakeProfilePicture = createAsyncThunk(
  "ProfilePicture/loadMakeProfilePicture",
  async (args: { user_id: string; profile_image: string }, thunkAPI) => {
    return axios.post<UploadProfilePictureResponse>(
      `${envConfig?.remoteApi}/profilepicture/makeProfileImage`,
      args,
      { headers }
    );
  }
);

export const ProfilePictureSlice = createSlice({
  name: "ProfilePicture",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // loadProfilePicture
      .addCase(loadGetProfilePicture.pending, (state): ProfilePictureState => {
        return {
          ...state,
          getProfilePicture: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadGetProfilePicture.fulfilled, (state, action): ProfilePictureState => {
        return {
          ...state,
          getProfilePicture: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadGetProfilePicture.rejected, (state, action): ProfilePictureState => {
        return {
          ...state,
          getProfilePicture: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadUploadProfilePicture
      .addCase(loadUploadProfilePicture.pending, (state): ProfilePictureState => {
        return {
          ...state,
          uploadProfilePicture: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadUploadProfilePicture.fulfilled, (state, action): ProfilePictureState => {
        return {
          ...state,
          uploadProfilePicture: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadUploadProfilePicture.rejected, (state, action): ProfilePictureState => {
        return {
          ...state,
          uploadProfilePicture: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadGetUploadedProfilePicture
      .addCase(loadGetUploadedProfilePicture.pending, (state): ProfilePictureState => {
        return {
          ...state,
          getUploadedProfilePicture: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadGetUploadedProfilePicture.fulfilled, (state, action): ProfilePictureState => {
        return {
          ...state,
          getUploadedProfilePicture: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadGetUploadedProfilePicture.rejected, (state, action): ProfilePictureState => {
        return {
          ...state,
          getUploadedProfilePicture: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadMakeProfilePicture
      .addCase(loadMakeProfilePicture.pending, (state): ProfilePictureState => {
        return {
          ...state,
          makeProfilePicture: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadMakeProfilePicture.fulfilled, (state, action): ProfilePictureState => {
        return {
          ...state,
          makeProfilePicture: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadMakeProfilePicture.rejected, (state, action): ProfilePictureState => {
        return {
          ...state,
          makeProfilePicture: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      });
  },
});

// export the combined reducers
export const ProfilePictureReducer = ProfilePictureSlice.reducer;

// Other code such as selectors can use the imported `RootState` type
export const selectProfilePicture = (state: RootState) => state.profilePictureReducer;
