import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "src/stores";
import { AsyncResponseState, AsyncStateType, Headers } from "src/stores/shares/utility";
import { MatchmakerDetailResponse, MatchmakerResponse, MatchmakerType } from "./types";
import { envConfig } from "src/config/envConfig";
const headers = Headers();

// Define a type for the slice state
export interface MatchmakerState {
  getMatchmakerDetail: AsyncResponseState<AxiosResponse<MatchmakerDetailResponse>>;
  getMatchmakers: AsyncResponseState<AxiosResponse<MatchmakerResponse>>;
  addMatchMaker: AsyncResponseState<AxiosResponse<MatchmakerResponse>>;
  updateMatchMaker: AsyncResponseState<AxiosResponse<MatchmakerResponse>>;
}

// Define the initial state using that type
const initialState: MatchmakerState = {
  getMatchmakerDetail: {
    currentState: AsyncStateType.INITIAL,
  },
  getMatchmakers: {
    currentState: AsyncStateType.INITIAL,
  },
  addMatchMaker: {
    currentState: AsyncStateType.INITIAL,
  },
  updateMatchMaker: {
    currentState: AsyncStateType.INITIAL,
  },
};

export const loadMatchmakerDetail = createAsyncThunk(
  "matchmakers/loadMatchmakerDetail",
  async (userId: string, thunkAPI) => {
    return axios.post<MatchmakerDetailResponse>(
      `${envConfig?.remoteApi}/admin2/MatchmakerDetails`,
      {
        id: userId,
      },
      { headers }
    );
  }
);

export const loadMatchmakers = createAsyncThunk(
  "matchmakers/loadMatchmakers",
  async (args, thunkAPI) => {
    return axios.post<MatchmakerResponse>(
      `${envConfig?.remoteApi}/admin/matchmakerslist`,
      {
        length: 10,
        start: 0,
        draw: 1,
        column_name: "firstname",
        order: "asc",
        search: "",
      },
      { headers }
    );
  }
);

export const loadAddMatchMaker = createAsyncThunk(
  "matchmakers/loadAddMatchMaker",
  async (args: FormData | MatchmakerType, thunkAPI) => {
    return axios.post<MatchmakerResponse>(`${envConfig?.remoteApi}/admin/matchmaker`, args, {
      headers,
    });
  }
);

export const loadUpdateMatchMaker = createAsyncThunk(
  "matchmakers/loadUpdateMatchMaker",
  async (args: MatchmakerType | FormData, thunkAPI) => {
    return axios.post<MatchmakerResponse>(`${envConfig?.remoteApi}/admin/updateMatchMaker`, args, {
      headers,
    });
  }
);

export const MatchmakerSlice = createSlice({
  name: "matchmakers",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // loadMatchmakerDetail
      .addCase(loadMatchmakerDetail.pending, (state): MatchmakerState => {
        return {
          ...state,
          getMatchmakerDetail: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadMatchmakerDetail.fulfilled, (state, action): MatchmakerState => {
        return {
          ...state,
          getMatchmakerDetail: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadMatchmakerDetail.rejected, (state, action): MatchmakerState => {
        return {
          ...state,
          getMatchmakerDetail: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadMatchmakers
      .addCase(loadMatchmakers.pending, (state): MatchmakerState => {
        return {
          ...state,
          getMatchmakers: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadMatchmakers.fulfilled, (state, action): MatchmakerState => {
        return {
          ...state,
          getMatchmakers: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadMatchmakers.rejected, (state, action): MatchmakerState => {
        return {
          ...state,
          getMatchmakers: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadAddMatchMaker
      .addCase(loadAddMatchMaker.pending, (state): MatchmakerState => {
        return {
          ...state,
          addMatchMaker: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadAddMatchMaker.fulfilled, (state, action): MatchmakerState => {
        return {
          ...state,
          addMatchMaker: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadAddMatchMaker.rejected, (state, action): MatchmakerState => {
        return {
          ...state,
          addMatchMaker: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadUpdateMatchMaker
      .addCase(loadUpdateMatchMaker.pending, (state): MatchmakerState => {
        return {
          ...state,
          updateMatchMaker: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadUpdateMatchMaker.fulfilled, (state, action): MatchmakerState => {
        return {
          ...state,
          updateMatchMaker: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadUpdateMatchMaker.rejected, (state, action): MatchmakerState => {
        return {
          ...state,
          updateMatchMaker: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      });
  },
});

// export the combined reducers
export const MatchmakerReducer = MatchmakerSlice.reducer;

// Other code such as selectors can use the imported `RootState` type
export const selectMatchmaker = (state: RootState) => state.matchmakerReducer;
