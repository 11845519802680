import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "src/stores";
import { AsyncResponseState, AsyncStateType, Headers } from "src/stores/shares/utility";
import { PotentialResponse } from "./types";
import { envConfig } from "src/config/envConfig";
const headers = Headers();

// Define a type for the slice state
export interface PotentialState extends AsyncResponseState<AxiosResponse<PotentialResponse>> {}

// Define the initial state using that type
const initialState: PotentialState = {
  currentState: AsyncStateType.INITIAL,
};

export const loadPotential = createAsyncThunk(
  "potential/loadPotential",
  async (userId: string, thunkAPI) => {
    return axios.post<PotentialResponse>(
      `${envConfig?.remoteApi}/admin2/getPotential`,
      {
        userid: userId,
      },
      { headers }
    );
  }
);

export const PotentialSlice = createSlice({
  name: "potential",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadPotential.pending, (state): PotentialState => {
        return {
          ...state,
          currentState: AsyncStateType.PENDING,
        };
      })
      .addCase(loadPotential.fulfilled, (state, action): PotentialState => {
        return {
          ...state,
          currentState: AsyncStateType.FULFILLED,
          response: action.payload,
        };
      })
      .addCase(loadPotential.rejected, (state, action): PotentialState => {
        return {
          ...state,
          currentState: AsyncStateType.REJECTED,
          error: `error: ${action.error.code} ${action.error.message}`,
        };
      });
  },
});

// export the combined reducers
export const PotentialReducer = PotentialSlice.reducer;

// Other code such as selectors can use the imported `RootState` type
export const selectPotential = (state: RootState) => state.potentialReducer;
