import { makeStyles } from "@mui/styles";

export const adminStyle = makeStyles({
  mainBoxContainer: {
    flexGrow: 1,
    opacity: 1,
    "&:before": {
      content: '""',
      background: "rgba(0,0,0,0.4)",
      left: "0",
      top: "0",
      width: "100%",
      height: "100vh",
      zIndex: 0,
    },
    padding: "24px",
    minHeight: "100vh",
    backgroundColor: "#f3f3f4",
    backgroundPosition: "center",
    backgroundSize: "cover",
    overflow: "hidden",
    position: "relative",
  },
  notificationBox: {
    padding: "8px 2px",
    display: "flex",
    background: "#E0F2DC",
    marginBottom: "3px",
    color: "#585F69",
  },
});
