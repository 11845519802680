import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "src/stores";
import { AsyncResponseState, AsyncStateType, Headers } from "src/stores/shares/utility";
import { EventDetailResponse, EventListResponse, EventListType } from "./types";
import { envConfig } from "src/config/envConfig";
const headers = Headers();

// Define a type for the slice state
export interface EventListState {
  getEventList: AsyncResponseState<AxiosResponse<EventListResponse>>;
  addEvent: AsyncResponseState<AxiosResponse<EventListResponse>>;
  getEventDetail: AsyncResponseState<AxiosResponse<EventDetailResponse>>;
  editEventDetail: AsyncResponseState<AxiosResponse<EventDetailResponse>>;
  deleteEvent: AsyncResponseState<AxiosResponse<EventDetailResponse>>;
  updateStatus: AsyncResponseState<AxiosResponse<EventDetailResponse>>;
  pushEventNotification: AsyncResponseState<AxiosResponse<EventDetailResponse>>;
  pushGeneralNotification: AsyncResponseState<AxiosResponse<EventDetailResponse>>;
}

// Define the initial state using that type
const initialState: EventListState = {
  getEventList: {
    currentState: AsyncStateType.INITIAL,
  },
  addEvent: {
    currentState: AsyncStateType.INITIAL,
  },
  getEventDetail: {
    currentState: AsyncStateType.INITIAL,
  },
  editEventDetail: {
    currentState: AsyncStateType.INITIAL,
  },
  deleteEvent: {
    currentState: AsyncStateType.INITIAL,
  },
  updateStatus: {
    currentState: AsyncStateType.INITIAL,
  },
  pushEventNotification: {
    currentState: AsyncStateType.INITIAL,
  },
  pushGeneralNotification: {
    currentState: AsyncStateType.INITIAL,
  },
};

export const loadGetEventList = createAsyncThunk(
  "getUsersListEvent/loadGetEventList",
  async (args, thunkAPI) => {
    return axios.get<EventListResponse>(`${envConfig?.remoteApi}/admin/events_list`, { headers });
  }
);

export const loadAddEvent = createAsyncThunk(
  "addEvent/loadAddEvent",
  async (args: FormData | EventListType, thunkAPI) => {
    return axios.post<EventListResponse>(
      `${envConfig?.remoteApi}/admin/add_events`,
      args,
      {
        headers: {
          ...headers,
          "Content-Type": "multipart/form-data",
        },
        data: args,
      }
    );
  }
);

export const loadGetEventDetail = createAsyncThunk(
  "getEventDetail/loadGetEventDetail",
  async (args: number, thunkAPI) => {
    return axios.get<EventDetailResponse>(`${envConfig?.remoteApi}/admin/event/${args}`, {
      headers,
    });
  }
);

export const loadEditEventDetail = createAsyncThunk(
  "getEventDetail/loadEditEventDetail",
  async (args: FormData | EventListType, thunkAPI) => {
    return axios.post<EventDetailResponse>(
      `${envConfig?.remoteApi}/admin/edit_events`,
      args,
      {
        headers: {
          ...headers,
          "Content-Type": "multipart/form-data",
        },
        data: args,
      }
    );
  }
);

// export const loadEditEventDetail = createAsyncThunk(
//   "getEventDetail/loadEditEventDetail",
//   async (args: EventListType, thunkAPI) => {
//     if (args && args?.id && args?.image && args?.title && args?.location && args?.description) {
//       const formData = new FormData();
//       formData.append("id", args?.id);
//       typeof args?.image === "object" && formData.append("image", args?.image);
//       formData.append("title", args?.title);
//       formData.append("location", args?.location);
//       formData.append("latitude", `${args?.latitude}`);
//       formData.append("longitude", `${args?.longitude}`);
//       formData.append("description", args?.description);
//       formData.append("start_date", `${args?.start_date}`);
//       formData.append("end_date", `${args?.end_date}`);
//       formData.append("price", `${args?.price}`);
//       formData.append("type", `${args?.type}`);
//       return axios.post<EventDetailResponse>(
//         `${envConfig?.remoteApi}/admin/edit_events`,
//         formData,
//         {
//           headers: {
//             ...headers,
//             "Content-Type": "multipart/form-data",
//           },
//         }
//       );
//     }
//   }
// );

export const loadDeleteEvent = createAsyncThunk(
  "deleteEvent/loadDeleteEvent",
  async (args: number, thunkAPI) => {
    return axios.delete<EventDetailResponse>(
      `${envConfig?.remoteApi}/admin/delete_events/${args}`,
      {
        headers,
      }
    );
  }
);

export const loadUpdateStatus = createAsyncThunk(
  "updateStatus/loadUpdateStatus",
  async (args: { event_id: number; status: string, description_status: string, reason: string }, thunkAPI) => {
    return axios.post<EventDetailResponse>(
      `${envConfig?.remoteApi}/admin/changeEventStatus`,
      args,
      {
        headers,
      }
    );
  }
);

export const loadPushEventNotification = createAsyncThunk(
  "pushEventNotification/loadPushEventNotification",
  async (args: { event_id: number; text_message: string }, thunkAPI) => {
    return axios.post<EventDetailResponse>(
      `${envConfig?.remoteApi}/admin/sendEventPushNotification`,
      args,
      {
        headers,
      }
    );
  }
);

export const loadPushGeneralNotification = createAsyncThunk(
  "pushGeneralNotification/loadPushGeneralNotification",
  async (args: { text_message: string }, thunkAPI) => {
    return axios.post<EventDetailResponse>(
      `${envConfig?.remoteApi}/admin/sendGeneralPushNotification`,
      args,
      {
        headers,
      }
    );
  }
);

export const EventListSlice = createSlice({
  name: "EventList",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // loadGetEventList
      .addCase(loadGetEventList.pending, (state): EventListState => {
        return {
          ...state,
          getEventList: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadGetEventList.fulfilled, (state, action): EventListState => {
        return {
          ...state,
          getEventList: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadGetEventList.rejected, (state, action): EventListState => {
        return {
          ...state,
          getEventList: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadAddEvent
      .addCase(loadAddEvent.pending, (state): EventListState => {
        return {
          ...state,
          addEvent: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadAddEvent.fulfilled, (state, action): EventListState => {
        return {
          ...state,
          addEvent: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadAddEvent.rejected, (state, action): EventListState => {
        return {
          ...state,
          addEvent: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadGetEventDetail
      .addCase(loadGetEventDetail.pending, (state): EventListState => {
        return {
          ...state,
          getEventDetail: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadGetEventDetail.fulfilled, (state, action): EventListState => {
        return {
          ...state,
          getEventDetail: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadGetEventDetail.rejected, (state, action): EventListState => {
        return {
          ...state,
          getEventDetail: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadEditEventDetail
      .addCase(loadEditEventDetail.pending, (state): EventListState => {
        return {
          ...state,
          editEventDetail: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadEditEventDetail.fulfilled, (state, action): EventListState => {
        return {
          ...state,
          editEventDetail: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadEditEventDetail.rejected, (state, action): EventListState => {
        return {
          ...state,
          editEventDetail: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadDeleteEvent
      .addCase(loadDeleteEvent.pending, (state): EventListState => {
        return {
          ...state,
          deleteEvent: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadDeleteEvent.fulfilled, (state, action): EventListState => {
        return {
          ...state,
          deleteEvent: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadDeleteEvent.rejected, (state, action): EventListState => {
        return {
          ...state,
          deleteEvent: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      });
  },
});

// export the combined reducers
export const EventListReducer = EventListSlice.reducer;

// Other code such as selectors can use the imported `RootState` type
export const selectEventList = (state: RootState) => state.eventListReducer;
