import { createTheme } from "@mui/material";

export const brand = {
  colors: {
    primary: "#9BC4C4",
    second: "#FFC600",
    backgroundLight: "#fff",
    backgroundDark: "#000",
    backgroundGlass: "#ffffff96",
    textLight: "#fff",
    textDark: "#006e6b",
  },
  fonts: {
    family: {
      default: "Raleway, Arial, sans-serif",
    },
  },
};

const theme = createTheme({
  palette: {
    primary: {
      main: brand.colors.primary,
    },
  },
  typography: {
    fontSize: 12,
    fontFamily: brand.fonts.family.default,
  },
  components: {
    MuiTableRow: {
      styleOverrides: {
        root: {
          height: "40px",
          "&:nth-of-type(odd)": {
            backgroundColor: "#f9f9f9",
            "&:hover": {
              backgroundColor: "#f5f5f5",
            },
          },
          "&:nth-of-type(even)": {
            "&:hover": {
              backgroundColor: "#f5f5f5",
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          border: "1px solid #e7e7e7",
        },
        head: {
          borderBottom: "1px solid #111",
        },
      },
    },
  },
});

export default theme;
