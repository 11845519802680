import { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
const Chatting = lazy(() => import("src/pages/chatting"));
const Login = lazy(() => import("src/pages/login"));
const LandingPage = lazy(() => import("src/pages/LandingPage"));
const ProfilePicPage = lazy(() => import("src/pages/profile-pic"));
const NotificationsPage = lazy(() => import("src/pages/notifications"));
const AdminDashboard = lazy(() => import("src/pages/dashboard"));
const ActivityLog = lazy(() => import("src/pages/users/activity-log"));
const BlindDate = lazy(() => import("src/pages/users/blind-date"));
const AddBlind = lazy(() => import("src/pages/users/blind-date/add-blind"));
const EditBlind = lazy(() => import("src/pages/users/blind-date/edit-blind"));
const RejectedUsers = lazy(() => import("src/pages/rejected-users"));
const UserDetails = lazy(() => import("src/pages/profile-details"));
const ProfileUpdate = lazy(() => import("src/pages/profile-details/profile-update"));
const ProfileUpdate2 = lazy(() => import("src/pages/profile-details/profile-update/profile2"));
const ProfileUpdate3 = lazy(() => import("src/pages/profile-details/profile-update/profile3"));
const ProfileUpdate4 = lazy(() => import("src/pages/profile-details/profile-update/profile4"));
const ProfileUpdate5 = lazy(() => import("src/pages/profile-details/profile-update/profile5"));
const ProfileUpdate6 = lazy(() => import("src/pages/profile-details/profile-update/profile6"));
const MatchMakerQuestion = lazy(() => import("src/pages/profile-details/match-maker-question"));
const DeletedUsers = lazy(() => import("src/pages/deleted-users"));
const InterestedUsers = lazy(() => import("src/pages/interested-users"));
const Matchmakers = lazy(() => import("src/pages/matchmakers"));
const EventCoordinators = lazy(() => import("src/pages/event-coordinators"));
const UpdateEventCoordinators = lazy(() => import("src/pages/event-coordinators/event-coordinators-edit"));
const AddMatchmaker = lazy(() => import("src/pages/matchmakers/add-matchmaker"));
const AddEventCoordinators = lazy(() => import("src/pages/event-coordinators/add-event-coordinators"));
const EditMatchmaker = lazy(() => import("src/pages/matchmakers/edit-marchmaker"));
const ContactLeads = lazy(() => import("src/pages/contact-leads"));
const UserTips = lazy(() => import("src/pages/user-tips"));
const ReferanceUsers = lazy(() => import("src/pages/referance"));
const UserBlogs = lazy(() => import("src/pages/user-blogs"));
const AddBlogg = lazy(() => import("src/pages/user-blogs/add-blog"));
const SearchContent = lazy(() => import("src/pages/search-content"));
const RemoveCookies = lazy(() => import("src/pages/remove-cookies"));
const UserProfile = lazy(() => import("src/pages/user-profile"));
const AllUsersList = lazy(() => import("src/pages/users-list"));
const AcceptedUsersList = lazy(() => import("src/pages/users-list/accepted-users"));
const PendingUsersList = lazy(() => import("src/pages/users-list/pending-users"));
const ActiveUsersList = lazy(() => import("src/pages/users-list/active-users"));
const OnlineUsersList = lazy(() => import("src/pages/users-list/online-users"));
const MyMatch = lazy(() => import("src/pages/users-list/myMatch"));
const FinlMatch = lazy(() => import("src/pages/users-list/finalMatch"));
const ExploreDetail = lazy(() => import("src/pages/users-list/explore-detail"));
const RequestDetail = lazy(() => import("src/pages/users-list/request-detail"));
const UserRegistration = lazy(() => import("src/pages/users-list/user-registration"));
const AssignUser = lazy(() => import("src/pages/assign-users"));
const EventList = lazy(() => import("src/pages/event-list"));
const AddEvent = lazy(() => import("src/pages/event-list/add-event"));
const EditEvent = lazy(() => import("src/pages/event-list/edit-event"));
const EventSuggestionList = lazy(() => import("src/pages/event-list/suggestion"));
const AddEventSuggestion = lazy(() => import("src/pages/event-list/suggestion/add-event"));
const EditEventSuggestion = lazy(() => import("src/pages/event-list/suggestion/edit-event"));
const EventDescription = lazy(() => import("src/pages/event-list/description"));
const AddEventDescription = lazy(() => import("src/pages/event-list/description/add-description"));
const EditEventDescription = lazy(() => import("src/pages/event-list/description/edit-description"));
const EventContact = lazy(() => import("src/pages/contact"));
const AddEventContact = lazy(() => import("src/pages/contact/add-contact"));
const EditEventContact = lazy(() => import("src/pages/contact/edit-contact"));
const Ticket = lazy(() => import("src/pages/ticket"));
const AddUser = lazy(() => import("src/pages/search-content/add-user"));
const EditUser = lazy(() => import("src/pages/search-content/edit-user"));
const Payment = lazy(() => import("src/pages/Payment"));
const PaymentSuccess = lazy(() => import("src/pages/PaymentSuccess/PaymentSuccess"));
const ValidateTicket = lazy(() => import("src/pages/ticket/validate-ticket"));

export const AdminRouting = () => {
  return (
    <Routes>
      <Route path="*" element={<Navigate to="/admin/dashboard" replace />} />
      <Route path="/admin/dashboard" element={<AdminDashboard />} />
      <Route path="/admin/dashboard" element={<AdminDashboard />} />
      <Route path="/admin/aktivitet" element={<ActivityLog />} />
      <Route path="/admin/blindDate" element={<BlindDate />} />
      <Route path="/admin/addBlindDate" element={<AddBlind />} />
      <Route path="/admin/addBlindDate/:id" element={<EditBlind />} />
      <Route path="/admin/brukerdetaljer/:id" element={<UserDetails />} />
      <Route path="/admin/profiloppdatering1/:id" element={<ProfileUpdate />} />
      <Route path="/admin/profiloppdatering2/:id" element={<ProfileUpdate2 />} />
      <Route path="/admin/profiloppdatering3/:id" element={<ProfileUpdate3 />} />
      <Route path="/admin/profiloppdatering4/:id" element={<ProfileUpdate4 />} />
      <Route path="/admin/profiloppdatering5/:id" element={<ProfileUpdate5 />} />
      <Route path="/admin/profiloppdatering6/:id" element={<ProfileUpdate6 />} />
      <Route path="/admin/matchmakerquestions/:id" element={<MatchMakerQuestion />} />
      <Route path="/admin/profilbilde/:id" element={<ProfilePicPage />} />
      <Route path="/admin/varsel" element={<NotificationsPage />} />
      <Route path="/admin/abuse" element={<RejectedUsers />} />
      <Route path="/admin/chatting" element={<Chatting />} />
      <Route path="/admin/slettedebrukere" element={<DeletedUsers />} />
      <Route path="/admin/interessert" element={<InterestedUsers />} />
      <Route path="/admin/matchmaker" element={<Matchmakers />} />
      <Route path="/admin/event-coordinators" element={<EventCoordinators />} />
      <Route path="/admin/event-coordinators/:id" element={<UpdateEventCoordinators />} />
      <Route path="/admin/leggtilmatchmaker" element={<AddMatchmaker />} />
      <Route path="/admin/leggtilevent-coordinators" element={<AddEventCoordinators />} />
      <Route path="/admin/matchmaker/:id" element={<EditMatchmaker />} />
      <Route path="/admin/kontaktliste" element={<ContactLeads />} />
      <Route path="/admin/tips_oss" element={<UserTips />} />
      <Route path="/admin/referance" element={<ReferanceUsers />} />
      <Route path="/admin/blogg" element={<UserBlogs />} />
      <Route path="/admin/leggtilblogg" element={<AddBlogg />} />
      {/* <Route path="/admin/søk" element={<SearchContent />} /> */}
      <Route path="/admin/user" element={<SearchContent />} />
      <Route path="/admin/fjerncookie" element={<RemoveCookies />} />
      <Route path="/admin/brukerprofil" element={<UserProfile />} />
      <Route path="/admin/brukerliste/all" element={<AllUsersList />} />
      <Route path="/admin/brukerliste/accepted" element={<AcceptedUsersList />} />
      <Route path="/admin/brukerliste/pending" element={<PendingUsersList />} />
      <Route path="/admin/brukerliste/active" element={<ActiveUsersList />} />
      <Route path="/admin/brukerliste/online" element={<OnlineUsersList />} />
      <Route path="/admin/minmatch/:id" element={<MyMatch />} />
      <Route path="/admin/endreminmatch/:id" element={<FinlMatch />} />
      <Route path="/admin/utforskdetaljer/:id" element={<ExploreDetail />} />
      <Route path="/admin/forespørsler/:id" element={<RequestDetail />} />
      <Route path="/admin/tildelbruker/:id" element={<AssignUser />} />
      <Route path="/admin/brukerregistrering" element={<UserRegistration />} />
      <Route path="/admin/event" element={<EventList />} />
      <Route path="/admin/event/add" element={<AddEvent />} />
      <Route path="/admin/event/:id" element={<EditEvent />} />
      <Route path="/admin/event/suggestion" element={<EventSuggestionList />} />
      <Route path="/admin/event/suggestion/add" element={<AddEventSuggestion />} />
      <Route path="/admin/event/suggestion/:id" element={<EditEventSuggestion />} />
      <Route path="/admin/event/description" element={<EventDescription />} />
      <Route path="/admin/event/description/add" element={<AddEventDescription />} />
      <Route path="/admin/event/description/:id" element={<EditEventDescription />} />
      <Route path="/admin/event/contact" element={<EventContact />} />
      <Route path="/admin/event/contact/add" element={<AddEventContact />} />
      <Route path="/admin/event/contact/:id" element={<EditEventContact />} />
      <Route path="/admin/event/ticket" element={<Ticket />} />
      <Route path="/admin/user/add" element={<AddUser />} />
      <Route path="/admin/user/:id" element={<EditUser />} />
    </Routes>
  );
};

export const AdminLoginRouting = () => {
  return (
    <Routes>
      <Route path="*" element={<Navigate to="/admin/loginn" replace />} />
      <Route path="/admin/loginn" element={<Login />} />
      <Route path="/admin/landing" element={<LandingPage />} />
      <Route path="/admin/payment" element={<Payment />} />
      <Route path="/admin/payment-success" element={<PaymentSuccess />} />
      <Route path="/admin/ticket/validate" element={<ValidateTicket />} />
    </Routes>
  );
};
