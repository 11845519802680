import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "src/stores";
import { AsyncResponseState, AsyncStateType, Headers } from "src/stores/shares/utility";
import { ProfileUpdate4Response, ProfileUpdate4Type } from "./types";
import { envConfig } from "src/config/envConfig";
const headers = Headers();

// Define a type for the slice state
export interface ProfileUpdate4State
  extends AsyncResponseState<AxiosResponse<ProfileUpdate4Response>> {}

// Define the initial state using that type
const initialState: ProfileUpdate4State = {
  currentState: AsyncStateType.INITIAL,
};

export const loadProfileUpdate4 = createAsyncThunk(
  "profileUpdate4/loadProfileUpdate4",
  async (args: ProfileUpdate4Type, thunkAPI) => {
    return axios.post<ProfileUpdate4Response>(`${envConfig?.remoteApi}/home/proupdate4`, args, {
      headers,
    });
  }
);

export const ProfileUpdate4Slice = createSlice({
  name: "profileUpdate4",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadProfileUpdate4.pending, (state): ProfileUpdate4State => {
        return {
          ...state,
          currentState: AsyncStateType.PENDING,
        };
      })
      .addCase(loadProfileUpdate4.fulfilled, (state, action): ProfileUpdate4State => {
        return {
          ...state,
          currentState: AsyncStateType.FULFILLED,
          response: action.payload,
        };
      })
      .addCase(loadProfileUpdate4.rejected, (state, action): ProfileUpdate4State => {
        return {
          ...state,
          currentState: AsyncStateType.REJECTED,
          error: `error: ${action.error.code} ${action.error.message}`,
        };
      });
  },
});

// export the combined reducers
export const ProfileUpdate4Reducer = ProfileUpdate4Slice.reducer;

// Other code such as selectors can use the imported `RootState` type
export const selectProfileUpdate4 = (state: RootState) => state.profileUpdate4Reducer;
