import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "src/stores";
import { AsyncResponseState, AsyncStateType, Headers } from "src/stores/shares/utility";
import { ProfileUpdate5Response, ProfileUpdate5Type } from "./types";
import { envConfig } from "src/config/envConfig";
const headers = Headers();

// Define a type for the slice state
export interface ProfileUpdate5State
  extends AsyncResponseState<AxiosResponse<ProfileUpdate5Response>> {}

// Define the initial state using that type
const initialState: ProfileUpdate5State = {
  currentState: AsyncStateType.INITIAL,
};

export const loadProfileUpdate5 = createAsyncThunk(
  "profileUpdate5/loadProfileUpdate5",
  async (args: ProfileUpdate5Type, thunkAPI) => {
    return axios.post<ProfileUpdate5Response>(`${envConfig?.remoteApi}/home/proupdate5`, args, {
      headers,
    });
  }
);

export const ProfileUpdate5Slice = createSlice({
  name: "profileUpdate5",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadProfileUpdate5.pending, (state): ProfileUpdate5State => {
        return {
          ...state,
          currentState: AsyncStateType.PENDING,
        };
      })
      .addCase(loadProfileUpdate5.fulfilled, (state, action): ProfileUpdate5State => {
        return {
          ...state,
          currentState: AsyncStateType.FULFILLED,
          response: action.payload,
        };
      })
      .addCase(loadProfileUpdate5.rejected, (state, action): ProfileUpdate5State => {
        return {
          ...state,
          currentState: AsyncStateType.REJECTED,
          error: `error: ${action.error.code} ${action.error.message}`,
        };
      });
  },
});

// export the combined reducers
export const ProfileUpdate5Reducer = ProfileUpdate5Slice.reducer;

// Other code such as selectors can use the imported `RootState` type
export const selectProfileUpdate5 = (state: RootState) => state.profileUpdate5Reducer;
