import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "src/stores";
import { AsyncResponseState, AsyncStateType, Headers } from "src/stores/shares/utility";
import { AbUseListResponse } from "./types";
import { envConfig } from "src/config/envConfig";
const headers = Headers();

// Define a type for the slice state
export interface AbUseListState {
  getAbUseList: AsyncResponseState<AxiosResponse<AbUseListResponse>>;
  unlockAbUse: AsyncResponseState<AxiosResponse<AbUseListResponse>>;
  deletedUser: AsyncResponseState<AxiosResponse<AbUseListResponse>>;
}

// Define the initial state using that type
const initialState: AbUseListState = {
  getAbUseList: {
    currentState: AsyncStateType.INITIAL,
  },
  unlockAbUse: {
    currentState: AsyncStateType.INITIAL,
  },
  deletedUser: {
    currentState: AsyncStateType.INITIAL,
  },
};

export const loadAbUseList = createAsyncThunk(
  "abUseList/loadAbUseList",
  async (args: boolean, thunkAPI) => {
    return axios.post<AbUseListResponse>(
      `${envConfig?.remoteApi}/admin/abUse`,
      {
        length: 65,
        start: 0,
        draw: 1,
        column_name: "id",
        order: "desc",
        search: "",
        listType: args,
      },
      { headers }
    );
  }
);

export const loadUnlockAbUse = createAsyncThunk(
  "abUseList/loadUnlockAbUse",
  async ({ from_id, touser_id }: { from_id: string; touser_id: string }, thunkAPI) => {
    return axios.post<AbUseListResponse>(
      `${envConfig?.remoteApi}/admin/unblock`,
      {
        from_id: from_id,
        touser_id: touser_id,
      },
      { headers }
    );
  }
);

export const loadDeleteBlockedUser = createAsyncThunk(
  "abUseList/loadDeleteBlockedUser",
  async ({ assing_users_id }: { assing_users_id: number }, thunkAPI) => {
    return axios.post<AbUseListResponse>(
      `${envConfig?.remoteApi}/admin/deleteBlockedUser`,
      {
        assing_users_id: assing_users_id,
      },
      { headers }
    );
  }
);

export const AbUseListSlice = createSlice({
  name: "abUseList",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // loadAbUseList
      .addCase(loadAbUseList.pending, (state): AbUseListState => {
        return {
          ...state,
          getAbUseList: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadAbUseList.fulfilled, (state, action): AbUseListState => {
        return {
          ...state,
          getAbUseList: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadAbUseList.rejected, (state, action): AbUseListState => {
        return {
          ...state,
          getAbUseList: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadUnlockAbUse
      .addCase(loadUnlockAbUse.pending, (state): AbUseListState => {
        return {
          ...state,
          unlockAbUse: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadUnlockAbUse.fulfilled, (state, action): AbUseListState => {
        return {
          ...state,
          unlockAbUse: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadUnlockAbUse.rejected, (state, action): AbUseListState => {
        return {
          ...state,
          unlockAbUse: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadDeleteBlockedUser
      .addCase(loadDeleteBlockedUser.pending, (state): AbUseListState => {
        return {
          ...state,
          deletedUser: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadDeleteBlockedUser.fulfilled, (state, action): AbUseListState => {
        return {
          ...state,
          deletedUser: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadDeleteBlockedUser.rejected, (state, action): AbUseListState => {
        return {
          ...state,
          deletedUser: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      });
  },
});

// export the combined reducers
export const AbUseListReducer = AbUseListSlice.reducer;

// Other code such as selectors can use the imported `RootState` type
export const selectAbUseList = (state: RootState) => state.abUseListReducer;
