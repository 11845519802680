import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "src/stores";
import { AsyncResponseState, AsyncStateType, Headers } from "src/stores/shares/utility";
import {
  ProfileStatusResponse,
  ProfileStatus,
  ProfileAvailabilityResponse,
  ProfileAvailabilityType,
} from "./types";
import { envConfig } from "src/config/envConfig";
const headers = Headers();

// Define a type for the slice state
export interface ProfileStatusState {
  profileAccept: AsyncResponseState<AxiosResponse<ProfileStatusResponse>>;
  profileReject: AsyncResponseState<AxiosResponse<ProfileStatusResponse>>;
  profileAvailability: AsyncResponseState<AxiosResponse<ProfileAvailabilityResponse>>;
}

// Define the initial state using that type
const initialState: ProfileStatusState = {
  profileAccept: {
    currentState: AsyncStateType.INITIAL,
  },
  profileReject: {
    currentState: AsyncStateType.INITIAL,
  },
  profileAvailability: {
    currentState: AsyncStateType.INITIAL,
  },
};

export const loadProfileAccept = createAsyncThunk(
  "profileStatus/loadProfileAccept",
  async (args: ProfileStatus, thunkAPI) => {
    return axios.post<ProfileStatusResponse>(
      `${envConfig?.remoteApi}/admin2/profile_accept`,
      args,
      { headers }
    );
  }
);

export const loadProfileReject = createAsyncThunk(
  "profileStatus/loadProfileReject",
  async (args: ProfileStatus, thunkAPI) => {
    return axios.post<ProfileStatusResponse>(
      `${envConfig?.remoteApi}/admin2/profile_reject`,
      args,
      { headers }
    );
  }
);

export const loadProfileAvailability = createAsyncThunk(
  "profileStatus/loadProfileAvailability",
  async (args: ProfileAvailabilityType, thunkAPI) => {
    return axios.post<ProfileAvailabilityResponse>(
      `${envConfig?.remoteApi}/payment/change_details`,
      args,
      { headers }
    );
  }
);

export const ProfileStatusSlice = createSlice({
  name: "profileStatus",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // loadProfileAccept
      .addCase(loadProfileAccept.pending, (state): ProfileStatusState => {
        return {
          ...state,
          profileAccept: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadProfileAccept.fulfilled, (state, action): ProfileStatusState => {
        return {
          ...state,
          profileAccept: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadProfileAccept.rejected, (state, action): ProfileStatusState => {
        return {
          ...state,
          profileAccept: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadProfileReject
      .addCase(loadProfileReject.pending, (state): ProfileStatusState => {
        return {
          ...state,
          profileReject: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadProfileReject.fulfilled, (state, action): ProfileStatusState => {
        return {
          ...state,
          profileReject: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadProfileReject.rejected, (state, action): ProfileStatusState => {
        return {
          ...state,
          profileReject: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadProfileAvailability
      .addCase(loadProfileAvailability.pending, (state): ProfileStatusState => {
        return {
          ...state,
          profileAvailability: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadProfileAvailability.fulfilled, (state, action): ProfileStatusState => {
        return {
          ...state,
          profileAvailability: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadProfileAvailability.rejected, (state, action): ProfileStatusState => {
        return {
          ...state,
          profileAvailability: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      });
  },
});

// export the combined reducers
export const ProfileStatusReducer = ProfileStatusSlice.reducer;

// Other code such as selectors can use the imported `RootState` type
export const selectProfileStatus = (state: RootState) => state.profileStatusReducer;
