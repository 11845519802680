import { Dialog, DialogActions, DialogContent, DialogTitle, Icon, IconButton } from "@mui/material";
import { alertStyle } from "./pageStyle";
import { ReactNode } from "react";

interface NotificationAlertProps {
  handleClose: () => void;
  open: boolean;
  title?: string;
  children?: ReactNode;
  action?: ReactNode;
}
export const NotificationAlert = ({
  handleClose,
  open,
  title,
  children,
  action,
}: NotificationAlertProps) => {
  const classes = alertStyle();
  return (
    <Dialog open={open} maxWidth="xs" fullWidth className={classes.notificationBox}>
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          color: "#9BC4C4",
          fontSize: "12px",
          fontWeight: "bold",
        }}
      >
        {title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <Icon
          sx={{
            color: "#9BC4C4",
            fontSize: "12px",
            fontWeight: "bold",
          }}
        >
          close
        </Icon>
      </IconButton>
      <DialogContent dividers>{children}</DialogContent>
      {action && (
        <DialogActions sx={{ justifyContent: "center" }} className={classes.notificationActionBox}>
          {action}
        </DialogActions>
      )}
    </Dialog>
  );
};
