import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "src/stores";
import { AsyncResponseState, AsyncStateType, Headers } from "src/stores/shares/utility";
import { RequestResponse } from "./types";
import { envConfig } from "src/config/envConfig";
const headers = Headers();

// Define a type for the slice state
export interface RequestState extends AsyncResponseState<AxiosResponse<RequestResponse>> {}

// Define the initial state using that type
const initialState: RequestState = {
  currentState: AsyncStateType.INITIAL,
};

export const loadRequest = createAsyncThunk(
  "request/loadRequest",
  async (userId: string, thunkAPI) => {
    return axios.post<RequestResponse>(
      `${envConfig?.remoteApi}/admin2/requests`,
      { user_id: userId },
      { headers }
    );
  }
);

export const RequestSlice = createSlice({
  name: "request",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadRequest.pending, (state): RequestState => {
        return {
          ...state,
          currentState: AsyncStateType.PENDING,
        };
      })
      .addCase(loadRequest.fulfilled, (state, action): RequestState => {
        return {
          ...state,
          currentState: AsyncStateType.FULFILLED,
          response: action.payload,
        };
      })
      .addCase(loadRequest.rejected, (state, action): RequestState => {
        return {
          ...state,
          currentState: AsyncStateType.REJECTED,
          error: `error: ${action.error.code} ${action.error.message}`,
        };
      });
  },
});

// export the combined reducers
export const RequestReducer = RequestSlice.reducer;

// Other code such as selectors can use the imported `RootState` type
export const selectRequest = (state: RootState) => state.requestReducer;
