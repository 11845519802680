import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "src/stores";
import { AsyncResponseState, AsyncStateType, Headers } from "src/stores/shares/utility";
import { MasterTableResponse } from "./types";
import { envConfig } from "src/config/envConfig";
const headers = Headers();

// Define a type for the slice state
export interface MasterTableState extends AsyncResponseState<AxiosResponse<MasterTableResponse>> {}

// Define the initial state using that type
const initialState: MasterTableState = {
  currentState: AsyncStateType.INITIAL,
};

export const loadMasterTable = createAsyncThunk(
  "masterTable/loadMasterTable",
  async (args, thunkAPI) => {
    return axios.get<MasterTableResponse>(`${envConfig?.remoteApi}/general/MasterTableList`, {
      headers,
    });
  }
);

export const MasterTableSlice = createSlice({
  name: "masterTable",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadMasterTable.pending, (state): MasterTableState => {
        return {
          ...state,
          currentState: AsyncStateType.PENDING,
        };
      })
      .addCase(loadMasterTable.fulfilled, (state, action): MasterTableState => {
        return {
          ...state,
          currentState: AsyncStateType.FULFILLED,
          response: action.payload,
        };
      })
      .addCase(loadMasterTable.rejected, (state, action): MasterTableState => {
        return {
          ...state,
          currentState: AsyncStateType.REJECTED,
          error: `error: ${action.error.code} ${action.error.message}`,
        };
      });
  },
});

// export the combined reducers
export const MasterTableReducer = MasterTableSlice.reducer;

// Other code such as selectors can use the imported `RootState` type
export const selectMasterTable = (state: RootState) => state.masterTableReducer;
