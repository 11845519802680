import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Button,
  CssBaseline,
  FormControl,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Toolbar,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { MouseEvent, useEffect, useState } from "react";
import i18Next from "src/i18Next/config";
import { useTranslation } from "react-i18next";
import { NotificationAlert } from "src/components/dialog-alert/notification-alert";
import {
  AsyncStateType,
  clearBearerToken,
  getAccessToken,
  getRootScope,
  clearUserRole,
  clearUserId
} from "src/stores/shares/utility";
import { useAppDispatch } from "src/stores";
import { useSelector } from "react-redux";
import { loadOnlineStatus, selectOnlineStatus } from "./onlineStatus-kit/slice";
import { FullScreenLoader } from "src/components/fullScreenLoader";
import { AccessTime } from "@mui/icons-material";
import { adminStyle } from "../pageStyle";
import { logOut, revokeToken } from "src/config/firebase.utils";
import { loadReadAllNotification } from "src/pages/notifications/notification-kit/slice";
import { envConfig } from "src/config/envConfig";

export const AdminTopbar = () => {
  const classes = adminStyle();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { response, currentState, error } = useSelector(selectOnlineStatus);
  useEffect(() => {
    if (currentState === AsyncStateType.INITIAL) {
      dispatch(loadOnlineStatus(getRootScope()?.user_id));
    }
  }, [currentState, dispatch]);
  const [language, setLanguage] = useState<string>("no");
  const { t } = useTranslation();
  useEffect(() => {
    i18Next.changeLanguage(language);
  }, [language]);

  const [openMenu, setOpenMenu] = useState<null | HTMLElement>(null);
  const open = Boolean(openMenu);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setOpenMenu(event.currentTarget);
  };
  const handleClose = () => {
    setOpenMenu(null);
  };
  const [notifications, setNotifications] = useState(false);

  const handleClickNotification = () => {
    if (response?.data?.result?.notification_count && getRootScope()?.user_id) {
      dispatch(loadReadAllNotification(getRootScope()?.user_id));
      dispatch(loadOnlineStatus(getRootScope()?.user_id));
    }
    setNotifications(true);
  };

  const handleCloseNotification = () => {
    setNotifications(false);
  };

  const handleAllAlert = () => {
    navigate(`/admin/varsel`);
    setNotifications(false);
  };

  const handleProfile = () => {
    navigate("/admin/brukerprofil");
    setOpenMenu(null);
  };
  const handleLogout = () => {
    setOpenMenu(null);
    clearBearerToken();
    clearUserRole();
    clearUserId();
    revokeToken(getAccessToken());
    logOut();
    document.cookie = `admindata=;expires=3600;path=/`;
    navigate(0);
  };

  return (
    <Box
      sx={{
        display: { xs: "block", sm: "flex" },
        padding: "0 15px",
        margin: "0 15px",
      }}
    >
      <CssBaseline />
      <AppBar
        sx={{
          // backgroundColor: "white",
          background:"#9BC4C4",
          borderBottom: "1px solid #e7eaec",
          boxShadow: "unset",
          height: "61px",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {/* <Button variant="contained" onClick={() => navigate("/admin/fjerncookie")}>
                {t(`AdminHeader.${[0]}.label`)}
              </Button> */}
              <FullScreenLoader loading={currentState === AsyncStateType.PENDING} error={error} />
              <IconButton sx={{ color: "white" }} onClick={handleClickNotification}>
                {currentState === AsyncStateType.FULFILLED && (
                  <Badge badgeContent={response?.data?.result?.notification_count} color="primary">
                    <Icon>notifications</Icon>
                  </Badge>
                )}
              </IconButton>
              <NotificationAlert
                open={notifications}
                handleClose={handleCloseNotification}
                title={t(`AdminHeader.${[1]}.children.${[0]}.label`)}
                action={
                  <Button
                    autoFocus
                    onClick={handleAllAlert}
                    sx={{ color: "#23527c", fontSize: "12px" }}
                  >
                    {t(`AdminHeader.${[1]}.children.${[2]}.label`)}
                  </Button>
                }
              >
                {currentState === AsyncStateType.FULFILLED &&
                response?.data?.result?.data &&
                response?.data?.result?.data?.length > 0 ? (
                  response?.data?.result?.data?.map((notification) => (
                    <Box className={classes.notificationBox} key={notification?.id}>
                      <Avatar
                        src={`${envConfig?.profileURL}/storage/uploads/profile/${notification?.to_profile_image}`}
                        sx={{ background: "#fff", mr: "2rem" }}
                      />
                      <Box>
                        <Typography sx={{ fontSize: "13px" }}>
                          {notification?.to_first_name} {notification?.request_type}
                        </Typography>
                        <Typography
                          sx={{ fontSize: "13px", display: "flex", alignItems: "center" }}
                        >
                          <AccessTime sx={{ fontSize: "13px" }} />
                          <em>{notification?.date_time}</em>
                        </Typography>
                      </Box>
                    </Box>
                  ))
                ) : (
                  <Typography gutterBottom sx={{ color: "#9BC4C4", fontSize: "12px" }}>
                    {t(`AdminHeader.${[1]}.children.${[1]}.label`)}
                  </Typography>
                )}
              </NotificationAlert>
              <Button
                onClick={handleClick}
                endIcon={<Icon>keyboard_arrow_down</Icon>}
                sx={{ color: "white" }}
              >
                {t(`AdminHeader.${[2]}.label`)}
              </Button>
              <Menu
                anchorEl={openMenu}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: { ...menuPaperProps },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem onClick={handleProfile}>
                  <Icon fontSize="small">person</Icon>
                  {t(`AdminHeader.${[2]}.children.${[0]}.label`)}
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                  <Icon fontSize="small">logout</Icon>
                  {t(`AdminHeader.${[2]}.children.${[1]}.label`)}
                </MenuItem>
              </Menu>
              <Box sx={{ minWidth: 20, ml: 1 }}>
                <FormControl className={"languageChangeDropdown"} fullWidth>
                  <Select
                    id="demo-simple-select"
                    value={language}
                    onChange={(e) => setLanguage(e.target.value)}
                    sx={{ border: "1px solid white", color: "white" }}
                  >
                    <MenuItem value={"no"}>No</MenuItem>
                    <MenuItem value={"en"}>En</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
const menuPaperProps = {
  overflow: "visible",
  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
  mt: 1.5,
  "& .MuiAvatar-root": {
    width: 32,
    height: 32,
    ml: -0.5,
    mr: 1,
  },
  "&:before": {
    content: '""',
    display: "block",
    position: "absolute",
    top: 0,
    right: 14,
    width: 10,
    height: 10,
    bgcolor: "background.paper",
    transform: "translateY(-50%) rotate(45deg)",
    zIndex: 0,
  },
};
