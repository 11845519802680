import { makeStyles } from "@mui/styles";

export const footerStyle = makeStyles({
  footerBox: {
    display: "flex",
    color: "#676a6c",
    fontSize: "13px",
    background: "none repeat scroll 0 0 white",
    borderTop: "1px solid #e7eaec",
    bottom: "0",
    left: "0",
    padding: "10px 20px",
    right: "0",
    marginLeft: "220px",
  },
});
