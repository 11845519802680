import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "src/stores";
import { AsyncResponseState, AsyncStateType, Headers } from "src/stores/shares/utility";
import { ExploreDetailResponse } from "./types";
import { envConfig } from "src/config/envConfig";
const headers = Headers();

// Define a type for the slice state
export interface ExploreDetailState
  extends AsyncResponseState<AxiosResponse<ExploreDetailResponse>> {}

// Define the initial state using that type
const initialState: ExploreDetailState = {
  currentState: AsyncStateType.INITIAL,
};

export const loadExploreDetail = createAsyncThunk(
  "exploreDetail/loadExploreDetail",
  async (userId: string, thunkAPI) => {
    return axios.post<ExploreDetailResponse>(
      `${envConfig?.remoteApi}/admin2/exploredetail`,
      { user_id: userId },
      { headers }
    );
  }
);

export const ExploreDetailSlice = createSlice({
  name: "exploreDetail",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadExploreDetail.pending, (state): ExploreDetailState => {
        return {
          ...state,
          currentState: AsyncStateType.PENDING,
        };
      })
      .addCase(loadExploreDetail.fulfilled, (state, action): ExploreDetailState => {
        return {
          ...state,
          currentState: AsyncStateType.FULFILLED,
          response: action.payload,
        };
      })
      .addCase(loadExploreDetail.rejected, (state, action): ExploreDetailState => {
        return {
          ...state,
          currentState: AsyncStateType.REJECTED,
          error: `error: ${action.error.code} ${action.error.message}`,
        };
      });
  },
});

// export the combined reducers
export const ExploreDetailReducer = ExploreDetailSlice.reducer;

// Other code such as selectors can use the imported `RootState` type
export const selectExploreDetail = (state: RootState) => state.exploreDetailReducer;
