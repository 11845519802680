import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "src/stores";
import { AsyncResponseState, AsyncStateType, Headers } from "src/stores/shares/utility";
import { AssignBlindDateResponse } from "./types";
import { envConfig } from "src/config/envConfig";
const headers = Headers();

// Define a type for the slice state
export interface AssignBlindDateState
  extends AsyncResponseState<AxiosResponse<AssignBlindDateResponse>> {}

// Define the initial state using that type
const initialState: AssignBlindDateState = {
  currentState: AsyncStateType.INITIAL,
};

export const loadAssignBlindDate = createAsyncThunk(
  "assignBlindDate/loadAssignBlindDate",
  async (userId: string, thunkAPI) => {
    return axios.post<AssignBlindDateResponse>(
      `${envConfig?.remoteApi}/admin2/getBlindDate`,
      {
        userid: userId,
      },
      { headers }
    );
  }
);

export const AssignBlindDateSlice = createSlice({
  name: "assignBlindDate",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadAssignBlindDate.pending, (state): AssignBlindDateState => {
        return {
          ...state,
          currentState: AsyncStateType.PENDING,
        };
      })
      .addCase(loadAssignBlindDate.fulfilled, (state, action): AssignBlindDateState => {
        return {
          ...state,
          currentState: AsyncStateType.FULFILLED,
          response: action.payload,
        };
      })
      .addCase(loadAssignBlindDate.rejected, (state, action): AssignBlindDateState => {
        return {
          ...state,
          currentState: AsyncStateType.REJECTED,
          error: `error: ${action.error.code} ${action.error.message}`,
        };
      });
  },
});

// export the combined reducers
export const AssignBlindDateReducer = AssignBlindDateSlice.reducer;

// Other code such as selectors can use the imported `RootState` type
export const selectAssignBlindDate = (state: RootState) => state.assignBlindDateReducer;
