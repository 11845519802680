import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "src/stores";
import { AsyncResponseState, AsyncStateType, Headers } from "src/stores/shares/utility";
import { BlogListResponse, BlogResponse, BlogType } from "./types";
import { envConfig } from "src/config/envConfig";
const headers = Headers();

// Define a type for the slice state
export interface BlogListState {
  addBlog: AsyncResponseState<AxiosResponse<BlogResponse>>;
  blogList: AsyncResponseState<AxiosResponse<BlogListResponse>>;
  deleteBlog: AsyncResponseState<AxiosResponse<BlogResponse>>;
  blogDetail: AsyncResponseState<AxiosResponse<BlogResponse>>;
}

// Define the initial state using that type
const initialState: BlogListState = {
  addBlog: {
    currentState: AsyncStateType.INITIAL,
  },
  blogList: {
    currentState: AsyncStateType.INITIAL,
  },
  deleteBlog: {
    currentState: AsyncStateType.INITIAL,
  },
  blogDetail: {
    currentState: AsyncStateType.INITIAL,
  },
};

export const loadAddBlog = createAsyncThunk(
  "addBlog/loadAddBlog",
  async (blogData: BlogType, thunkAPI) => {
    return axios.post<BlogResponse>(`${envConfig?.remoteApi}/admin/blogData`, blogData, {
      headers,
    });
  }
);

export const loadBlogList = createAsyncThunk("blogList/loadBlogList", async (args, thunkAPI) => {
  return axios.get<BlogListResponse>(`${envConfig?.remoteApi}/admin/bloglist`, { headers });
});

export const loadDeleteBlog = createAsyncThunk(
  "blogList/loadDeleteBlog",
  async (args: number, thunkAPI) => {
    return axios.post<BlogResponse>(
      `${envConfig?.remoteApi}/admin/deleteBlog`,
      { blog_id: args },
      { headers }
    );
  }
);

export const loadBlogDetail = createAsyncThunk(
  "blogList/loadBlogDetail",
  async (args: number, thunkAPI) => {
    return axios.post<BlogResponse>(
      `${envConfig?.remoteApi}/admin/blogdetails`,
      { blog_id: args },
      { headers }
    );
  }
);

export const BlogListResponseSlice = createSlice({
  name: "blogList",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // getUsersList
      .addCase(loadAddBlog.pending, (state): BlogListState => {
        return {
          ...state,
          addBlog: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadAddBlog.fulfilled, (state, action): BlogListState => {
        return {
          ...state,
          addBlog: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadAddBlog.rejected, (state, action): BlogListState => {
        return {
          ...state,
          addBlog: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      //  BlogListResponse
      .addCase(loadBlogList.pending, (state): BlogListState => {
        return {
          ...state,
          blogList: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadBlogList.fulfilled, (state, action): BlogListState => {
        return {
          ...state,
          blogList: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadBlogList.rejected, (state, action): BlogListState => {
        return {
          ...state,
          blogList: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadDeleteBlog
      .addCase(loadDeleteBlog.pending, (state): BlogListState => {
        return {
          ...state,
          deleteBlog: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadDeleteBlog.fulfilled, (state, action): BlogListState => {
        return {
          ...state,
          deleteBlog: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadDeleteBlog.rejected, (state, action): BlogListState => {
        return {
          ...state,
          deleteBlog: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadBlogDetail
      .addCase(loadBlogDetail.pending, (state): BlogListState => {
        return {
          ...state,
          blogDetail: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadBlogDetail.fulfilled, (state, action): BlogListState => {
        return {
          ...state,
          blogDetail: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadBlogDetail.rejected, (state, action): BlogListState => {
        return {
          ...state,
          blogDetail: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      });
  },
});

// export the combined reducers
export const BlogListReducer = BlogListResponseSlice.reducer;

// Other code such as selectors can use the imported `RootState` type
export const selectBlogList = (state: RootState) => state.blogListReducer;
