import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "src/stores";
import { AsyncResponseState, AsyncStateType, Headers } from "src/stores/shares/utility";
import { AssignedMatchUserResponse, CurrentUserResponse, UserDetailResponse } from "./types";
import { envConfig } from "src/config/envConfig";
const headers = Headers();

// Define a type for the slice state
export interface AssignedMatchUserState {
  assignedMatchUser: AsyncResponseState<AxiosResponse<AssignedMatchUserResponse>>;
  currentUserProfile: AsyncResponseState<AxiosResponse<CurrentUserResponse>>;
  getUserDetail: AsyncResponseState<AxiosResponse<UserDetailResponse>>;
  addPotential: AsyncResponseState<AxiosResponse<AssignedMatchUserResponse>>;
}

// Define the initial state using that type
const initialState: AssignedMatchUserState = {
  assignedMatchUser: {
    currentState: AsyncStateType.INITIAL,
  },
  currentUserProfile: {
    currentState: AsyncStateType.INITIAL,
  },
  getUserDetail: {
    currentState: AsyncStateType.INITIAL,
  },
  addPotential: {
    currentState: AsyncStateType.INITIAL,
  },
};

export const loadAssignedMatchUser = createAsyncThunk(
  "assignedMatchUser/loadAssignedMatchUser",
  async (userId: string, thunkAPI) => {
    return axios.post<AssignedMatchUserResponse>(
      `${envConfig?.remoteApi}/admin2/notmatchingUsers`,
      {
        column_name: "profile_image",
        draw: 1,
        length: 10,
        order: "asc",
        search: "",
        start: 0,
        user_id: userId,
      },
      { headers }
    );
  }
);

export const loadCurrentUser = createAsyncThunk(
  "currentUser/loadCurrentUser",
  async (userId: number, thunkAPI) => {
    return axios.post<CurrentUserResponse>(
      `${envConfig?.remoteApi}/admin2/getCurrentUserDetail`,
      {
        user_id: userId,
      },
      { headers }
    );
  }
);

export const loadUserDetail = createAsyncThunk(
  "userDetail/loadUserDetail",
  async (args: { user_id: number; assign_user: number }, thunkAPI) => {
    return axios.post<UserDetailResponse>(`${envConfig?.remoteApi}/admin2/getUserDetail`, args, {
      headers,
    });
  }
);

export const loadAddPotential = createAsyncThunk(
  "addPotential/loadAddPotential",
  async (args: { profileId: number; assignId: number }, thunkAPI) => {
    return axios.post<AssignedMatchUserResponse>(
      `${envConfig?.remoteApi}/admin2/addPotential`,
      args,
      {
        headers,
      }
    );
  }
);

export const AssignedMatchUserSlice = createSlice({
  name: "assignedMatchUser",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // loadAssignedMatchUser
      .addCase(loadAssignedMatchUser.pending, (state): AssignedMatchUserState => {
        return {
          ...state,
          assignedMatchUser: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadAssignedMatchUser.fulfilled, (state, action): AssignedMatchUserState => {
        return {
          ...state,
          assignedMatchUser: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadAssignedMatchUser.rejected, (state, action): AssignedMatchUserState => {
        return {
          ...state,
          assignedMatchUser: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadCurrentUser
      .addCase(loadCurrentUser.pending, (state): AssignedMatchUserState => {
        return {
          ...state,
          currentUserProfile: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadCurrentUser.fulfilled, (state, action): AssignedMatchUserState => {
        return {
          ...state,
          currentUserProfile: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadCurrentUser.rejected, (state, action): AssignedMatchUserState => {
        return {
          ...state,
          currentUserProfile: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadUserDetail
      .addCase(loadUserDetail.pending, (state): AssignedMatchUserState => {
        return {
          ...state,
          getUserDetail: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadUserDetail.fulfilled, (state, action): AssignedMatchUserState => {
        return {
          ...state,
          getUserDetail: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadUserDetail.rejected, (state, action): AssignedMatchUserState => {
        return {
          ...state,
          getUserDetail: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadAddPotential
      .addCase(loadAddPotential.pending, (state): AssignedMatchUserState => {
        return {
          ...state,
          addPotential: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadAddPotential.fulfilled, (state, action): AssignedMatchUserState => {
        return {
          ...state,
          addPotential: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadAddPotential.rejected, (state, action): AssignedMatchUserState => {
        return {
          ...state,
          addPotential: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      });
  },
});

// export the combined reducers
export const AssignedMatchUserReducer = AssignedMatchUserSlice.reducer;

// Other code such as selectors can use the imported `RootState` type
export const selectAssignedMatchUser = (state: RootState) => state.assignedMatchUserReducer;
