import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "src/stores";
import { AsyncResponseState, AsyncStateType, Headers } from "src/stores/shares/utility";
import { UserPreferenceResponse, UserPreferenceType } from "./types";
import { envConfig } from "src/config/envConfig";
const headers = Headers();

// Define a type for the slice state
export interface UserPreferenceState {
  getUserPreference: AsyncResponseState<AxiosResponse<UserPreferenceResponse>>;
  storeUserPreference: AsyncResponseState<AxiosResponse<UserPreferenceResponse>>;
}
// Define the initial state using that type
const initialState: UserPreferenceState = {
  getUserPreference: {
    currentState: AsyncStateType.INITIAL,
  },
  storeUserPreference: {
    currentState: AsyncStateType.INITIAL,
  },
};

export const loadUserPreference = createAsyncThunk(
  "userPreference/loadUserPreference",
  async (userId: string, thunkAPI) => {
    return axios.get<UserPreferenceResponse>(
      `${envConfig?.remoteApi}/preference/GetUserPreference?user_id=${userId}`,
      { headers }
    );
  }
);

export const loadStoreUserPreference = createAsyncThunk(
  "storeUserPreference/loadStoreUserPreference",
  async (args: UserPreferenceType, thunkAPI) => {
    return axios.post<UserPreferenceResponse>(
      `${envConfig?.remoteApi}/preference/StoreUserPreference`,
      args,
      { headers }
    );
  }
);

export const UserPreferenceSlice = createSlice({
  name: "userPreference",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // loadUserPreference
      .addCase(loadUserPreference.pending, (state): UserPreferenceState => {
        return {
          ...state,
          getUserPreference: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadUserPreference.fulfilled, (state, action): UserPreferenceState => {
        return {
          ...state,
          getUserPreference: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadUserPreference.rejected, (state, action): UserPreferenceState => {
        return {
          ...state,
          getUserPreference: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadStoreUserPreference
      .addCase(loadStoreUserPreference.pending, (state): UserPreferenceState => {
        return {
          ...state,
          storeUserPreference: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadStoreUserPreference.fulfilled, (state, action): UserPreferenceState => {
        return {
          ...state,
          storeUserPreference: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadStoreUserPreference.rejected, (state, action): UserPreferenceState => {
        return {
          ...state,
          storeUserPreference: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      });
  },
});

// export the combined reducers
export const UserPreferenceReducer = UserPreferenceSlice.reducer;

// Other code such as selectors can use the imported `RootState` type
export const selectUserPreference = (state: RootState) => state.userPreferenceReducer;
