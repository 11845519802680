import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "src/stores";
import { AsyncResponseState, AsyncStateType, Headers } from "src/stores/shares/utility";
import {
  EventSuggestionDetailResponse,
  EventSuggestionResponse,
  EventSuggestionType,
} from "./types";
import { envConfig } from "src/config/envConfig";
const headers = Headers();

// Define a type for the slice state
export interface EventSuggestionState {
  getEventSuggestion: AsyncResponseState<AxiosResponse<EventSuggestionResponse>>;
  addEventSuggestion: AsyncResponseState<AxiosResponse<EventSuggestionResponse>>;
  detailEventSuggestion: AsyncResponseState<AxiosResponse<EventSuggestionDetailResponse>>;
  editEventSuggestion: AsyncResponseState<AxiosResponse<EventSuggestionResponse>>;
  deleteEventSuggestion: AsyncResponseState<AxiosResponse<EventSuggestionResponse>>;
  statusEventSuggestion: AsyncResponseState<AxiosResponse<EventSuggestionResponse>>;
}

// Define the initial state using that type
const initialState: EventSuggestionState = {
  getEventSuggestion: {
    currentState: AsyncStateType.INITIAL,
  },
  addEventSuggestion: {
    currentState: AsyncStateType.INITIAL,
  },
  detailEventSuggestion: {
    currentState: AsyncStateType.INITIAL,
  },
  editEventSuggestion: {
    currentState: AsyncStateType.INITIAL,
  },
  deleteEventSuggestion: {
    currentState: AsyncStateType.INITIAL,
  },
  statusEventSuggestion: {
    currentState: AsyncStateType.INITIAL,
  },
};

export const loadGetEventSuggestion = createAsyncThunk(
  "getEventSuggestion/loadGetEventSuggestion",
  async ({ user_id, status }: { user_id?: string; status?: string }, thunkAPI) => {
    let queryParams = "";
    if (user_id !== undefined && status !== undefined) {
      queryParams = `?user_id=${user_id}&status=${status}`;
    } else if (user_id !== undefined) {
      queryParams = `?user_id=${user_id}`;
    } else if (status !== undefined) {
      queryParams = `?status=${status}`;
    }
    return axios.get<EventSuggestionResponse>(
      `${envConfig?.remoteApi}/admin/eventSuggestionList${queryParams}`,
      {
        headers,
      }
    );
  }
);

export const loadAddEventSuggestion = createAsyncThunk(
  "addEventSuggestion/loadAddEventSuggestion",
  async (args: EventSuggestionType, thunkAPI) => {
    return axios.post<EventSuggestionResponse>(
      `${envConfig?.remoteApi}/admin/createEventSuggestion`,
      args,
      {
        headers,
      }
    );
  }
);

export const loadEventSuggestionDetail = createAsyncThunk(
  "detailEventSuggestion/loadEventSuggestionDetail",
  async (args: number, thunkAPI) => {
    return axios.get<EventSuggestionDetailResponse>(
      `${envConfig?.remoteApi}/admin/getEventSuggestion/${args}`,
      {
        headers,
      }
    );
  }
);

export const loadEditEventSuggestion = createAsyncThunk(
  "editEventSuggestion/loadEditEventSuggestion",
  async (args: EventSuggestionType, thunkAPI) => {
    return axios.post<EventSuggestionResponse>(
      `${envConfig?.remoteApi}/admin/editEventSuggestion`,
      args,
      {
        headers,
      }
    );
  }
);

export const loadEventSuggestionDelete = createAsyncThunk(
  "deleteEventSuggestion/loadEventSuggestionDelete",
  async (args: number, thunkAPI) => {
    return axios.delete<EventSuggestionResponse>(
      `${envConfig?.remoteApi}/admin/deleteEventSuggestion/${args}`,
      {
        headers,
      }
    );
  }
);

export const loadEventSuggestionStatus = createAsyncThunk(
  "statusEventSuggestion/loadEventSuggestionStatus",
  async (args: { event_suggestion_id: number; status: string; reason: string }, thunkAPI) => {
    return axios.post<EventSuggestionResponse>(
      `${envConfig?.remoteApi}/admin/changeEventSuggestionStatus`,
      args,
      {
        headers,
      }
    );
  }
);

export const EventSuggestionSlice = createSlice({
  name: "EventSuggestion",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // loadGetEventSuggestion
      .addCase(loadGetEventSuggestion.pending, (state): EventSuggestionState => {
        return {
          ...state,
          getEventSuggestion: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadGetEventSuggestion.fulfilled, (state, action): EventSuggestionState => {
        return {
          ...state,
          getEventSuggestion: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadGetEventSuggestion.rejected, (state, action): EventSuggestionState => {
        return {
          ...state,
          getEventSuggestion: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadAddEventSuggestion
      .addCase(loadAddEventSuggestion.pending, (state): EventSuggestionState => {
        return {
          ...state,
          addEventSuggestion: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadAddEventSuggestion.fulfilled, (state, action): EventSuggestionState => {
        return {
          ...state,
          addEventSuggestion: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadAddEventSuggestion.rejected, (state, action): EventSuggestionState => {
        return {
          ...state,
          addEventSuggestion: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadEventSuggestionDetail
      .addCase(loadEventSuggestionDetail.pending, (state): EventSuggestionState => {
        return {
          ...state,
          detailEventSuggestion: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadEventSuggestionDetail.fulfilled, (state, action): EventSuggestionState => {
        return {
          ...state,
          detailEventSuggestion: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadEventSuggestionDetail.rejected, (state, action): EventSuggestionState => {
        return {
          ...state,
          detailEventSuggestion: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadEditEventSuggestion
      .addCase(loadEditEventSuggestion.pending, (state): EventSuggestionState => {
        return {
          ...state,
          editEventSuggestion: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadEditEventSuggestion.fulfilled, (state, action): EventSuggestionState => {
        return {
          ...state,
          editEventSuggestion: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadEditEventSuggestion.rejected, (state, action): EventSuggestionState => {
        return {
          ...state,
          editEventSuggestion: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadEventSuggestionDelete
      .addCase(loadEventSuggestionDelete.pending, (state): EventSuggestionState => {
        return {
          ...state,
          deleteEventSuggestion: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadEventSuggestionDelete.fulfilled, (state, action): EventSuggestionState => {
        return {
          ...state,
          deleteEventSuggestion: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadEventSuggestionDelete.rejected, (state, action): EventSuggestionState => {
        return {
          ...state,
          deleteEventSuggestion: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadEventSuggestionStatus
      .addCase(loadEventSuggestionStatus.pending, (state): EventSuggestionState => {
        return {
          ...state,
          statusEventSuggestion: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadEventSuggestionStatus.fulfilled, (state, action): EventSuggestionState => {
        return {
          ...state,
          statusEventSuggestion: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadEventSuggestionStatus.rejected, (state, action): EventSuggestionState => {
        return {
          ...state,
          statusEventSuggestion: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      });
  },
});

// export the combined reducers
export const EventSuggestionReducer = EventSuggestionSlice.reducer;

// Other code such as selectors can use the imported `RootState` type
export const selectEventSuggestion = (state: RootState) => state.eventSuggestionReducer;
