import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { combineReducers } from "redux";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import { OnlineStatusReducer } from "src/layout/topbar/onlineStatus-kit/slice";
import { AssignUserReducer } from "src/pages/assign-users/assignUser-kit/slice";
import { AssignedMatchUserReducer } from "src/pages/assign-users/assigned-match/assignedUser-kit/slice";
import { AssignBlindDateReducer } from "src/pages/assign-users/blind-date/assignBlindDate-kit/slice";
import { MatchDetailReducer } from "src/pages/assign-users/match-detail/matchDetail-kit/slice";
import { ReviewDetailReducer } from "src/pages/assign-users/match-detail/reviewDetail-kit/slice";
import { MatchingUsersReducer } from "src/pages/assign-users/matching-users/matchingUsers-kit/slice";
import { PotentialReducer } from "src/pages/assign-users/potential-match/potential-kit/slice";
import { OnlineUserReducer } from "src/pages/chatting/onlineUser-kit/slice";
import { ContactLeadReducer } from "src/pages/contact-leads/contact-kit/slice";
import { CurrentAssignReducer } from "src/pages/dashboard/overview/overview-kit/slice";
import { UserCountReducer } from "src/pages/dashboard/usersCount-kit/slice";
import { WaitingListReducer } from "src/pages/dashboard/waiting/waiting-kit/slice";
import { BlockUserReducer } from "src/pages/deleted-users/blockUser-kit/slice";
import { EventDescriptionReducer } from "src/pages/event-list/description/description-kit/slice";
import { EventListReducer } from "src/pages/event-list/event-kit/slice";
import { EventSuggestionReducer } from "src/pages/event-list/suggestion/suggestion-kit/slice";
import { InterestedReducer } from "src/pages/interested-users/interested-kit/slice";
import { LoginReducer } from "src/pages/login/login-kit/slice";
import { MatchmakerReducer } from "src/pages/matchmakers/matchmaker-kit/slice";
import { EventCoordinatorsReducer } from "src/pages/event-coordinators/event-coordinators-kit/slice";
import { NotificationReducer } from "src/pages/notifications/notification-kit/slice";
import { MakerQuestionReducer } from "src/pages/profile-details/match-maker-question/makerQuestion-kit/slice";
import { GetLocationReducer } from "src/pages/profile-details/profile-update/getLocation-kit/slice";
import { MasterTableReducer } from "src/pages/profile-details/profile-update/masterTable-kit/slice";
import { ProfileUpdate1Reducer } from "src/pages/profile-details/profile-update/profile1-kit/slice";
import { ProfileUpdate2Reducer } from "src/pages/profile-details/profile-update/profile2-kit/slice";
import { ProfileUpdate3Reducer } from "src/pages/profile-details/profile-update/profile3-kit/slice";
import { ProfileUpdate4Reducer } from "src/pages/profile-details/profile-update/profile4-kit/slice";
import { ProfileUpdate5Reducer } from "src/pages/profile-details/profile-update/profile5-kit/slice";
import { ProfileUpdate6Reducer } from "src/pages/profile-details/profile-update/profile6-kit/slice";
import { ProfileDetailReducer } from "src/pages/profile-details/profileDetail-kit/slice";
import { ProfileStatusReducer } from "src/pages/profile-details/profileStatus-kit/slice";
import { ProfilePictureReducer } from "src/pages/profile-pic/profileStatus-kit/slice";
import { ReferenceReducer } from "src/pages/referance/reference-kit/slice";
import { AbUseListReducer } from "src/pages/rejected-users/abUse-kit/slice";
import { UserReducer } from "src/pages/search-content/search-kit/slice";
import { BlogListReducer } from "src/pages/user-blogs/blog-kit/slice";
import { TipListReducer } from "src/pages/user-tips/tipList-kit/slice";
import { ExploreDetailReducer } from "src/pages/users-list/explore-detail/explore-kit/slice";
import { UserPreferenceReducer } from "src/pages/users-list/finalMatch/finalMatch-kit/slice";
import { PreferenceItemReducer } from "src/pages/users-list/finalMatch/preferenceItem-kit/slice";
import { HistoryReducer } from "src/pages/users-list/history/history-kit/slice";
import { PreferenceDetailReducer } from "src/pages/users-list/myMatch/myMatch-kit/slice";
import { RequestReducer } from "src/pages/users-list/request-detail/request-kit/slice";
import { UserListingReducer } from "src/pages/users-list/userListing-kit/slice";
import { UserRegistrationReducer } from "src/pages/users-list/userRegistration-kit/slice";
import { ActivityListReducer } from "src/pages/users/activity-log/activity-kit/slice";
import { BlindDateListReducer } from "src/pages/users/blind-date/blind-kit/slice";
import { EventContactReducer } from "src/pages/contact/contact-kit/slice";
import { TicketReducer } from "src/pages/ticket/ticket-lit/slice";
import { EventDetailReducer } from "src/pages/LandingPage/landingPage-kit/slice";

const rootReducer = combineReducers({
  loginReducer: LoginReducer,
  waitingListReducer: WaitingListReducer,
  activityListReducer: ActivityListReducer,
  blindDateListReducer: BlindDateListReducer,
  abUseListReducer: AbUseListReducer,
  userCountReducer: UserCountReducer,
  matchmakerReducer: MatchmakerReducer,
  eventCoordinatorsReducer: EventCoordinatorsReducer,
  userListingReducer: UserListingReducer,
  profileDetailReducer: ProfileDetailReducer,
  currentAssignReducer: CurrentAssignReducer,
  contactLeadReducer: ContactLeadReducer,
  historyReducer: HistoryReducer,
  potentialReducer: PotentialReducer,
  interestedReducer: InterestedReducer,
  onlineUserReducer: OnlineUserReducer,
  blockUserReducer: BlockUserReducer,
  tipListReducer: TipListReducer,
  referenceReducer: ReferenceReducer,
  onlineStatusReducer: OnlineStatusReducer,
  assignUserReducer: AssignUserReducer,
  assignedMatchUserReducer: AssignedMatchUserReducer,
  matchingUsersReducer: MatchingUsersReducer,
  matchDetailReducer: MatchDetailReducer,
  assignBlindDateReducer: AssignBlindDateReducer,
  blogListReducer: BlogListReducer,
  preferenceDetailReducer: PreferenceDetailReducer,
  exploreDetailReducer: ExploreDetailReducer,
  requestReducer: RequestReducer,
  userPreferenceReducer: UserPreferenceReducer,
  preferenceItemReducer: PreferenceItemReducer,
  masterTableReducer: MasterTableReducer,
  getLocationReducer: GetLocationReducer,
  profileUpdate1Reducer: ProfileUpdate1Reducer,
  profileUpdate2Reducer: ProfileUpdate2Reducer,
  profileUpdate3Reducer: ProfileUpdate3Reducer,
  profileUpdate4Reducer: ProfileUpdate4Reducer,
  profileUpdate5Reducer: ProfileUpdate5Reducer,
  profileUpdate6Reducer: ProfileUpdate6Reducer,
  makerQuestionReducer: MakerQuestionReducer,
  profileStatusReducer: ProfileStatusReducer,
  userRegistrationReducer: UserRegistrationReducer,
  notificationReducer: NotificationReducer,
  profilePictureReducer: ProfilePictureReducer,
  eventListReducer: EventListReducer,
  reviewDetailReducer: ReviewDetailReducer,
  eventSuggestionReducer: EventSuggestionReducer,
  eventDescriptionReducer: EventDescriptionReducer,
  eventContactReducer: EventContactReducer,
  userReducer: UserReducer,
  ticketReducer: TicketReducer,
  eventDetailReducer:EventDetailReducer,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;

const logger = createLogger();

const devMiddleware: any = process.env.NODE_ENV !== "production" ? [logger] : [];

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(...devMiddleware),
  devTools: true,
});

// Inferred type
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
