import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "src/stores";
import { AsyncResponseState, AsyncStateType, Headers } from "src/stores/shares/utility";
import {
  EventDescriptionDetailResponse,
  EventDescriptionResponse,
  EventDescriptionType,
} from "./types";
import { envConfig } from "src/config/envConfig";
const headers = Headers();

// Define a type for the slice state
export interface EventDescriptionState {
  getEventDescription: AsyncResponseState<AxiosResponse<EventDescriptionResponse>>;
  addEventDescription: AsyncResponseState<AxiosResponse<EventDescriptionResponse>>;
  detailEventDescription: AsyncResponseState<AxiosResponse<EventDescriptionDetailResponse>>;
  editEventDescription: AsyncResponseState<AxiosResponse<EventDescriptionResponse>>;
  deleteEventDescription: AsyncResponseState<AxiosResponse<EventDescriptionResponse>>;
  statusEventDescription: AsyncResponseState<AxiosResponse<EventDescriptionResponse>>;
}

// Define the initial state using that type
const initialState: EventDescriptionState = {
  getEventDescription: {
    currentState: AsyncStateType.INITIAL,
  },
  addEventDescription: {
    currentState: AsyncStateType.INITIAL,
  },
  detailEventDescription: {
    currentState: AsyncStateType.INITIAL,
  },
  editEventDescription: {
    currentState: AsyncStateType.INITIAL,
  },
  deleteEventDescription: {
    currentState: AsyncStateType.INITIAL,
  },
  statusEventDescription: {
    currentState: AsyncStateType.INITIAL,
  },
};

export const loadGetEventDescription = createAsyncThunk(
  "getEventDescription/loadGetEventDescription",
  async ({ user_id, is_approved }: { user_id?: string; is_approved?: number }, thunkAPI) => {
    let queryParams = "";
    if (user_id !== undefined && is_approved !== undefined) {
      queryParams = `?user_id=${user_id}&is_approved=${is_approved}`;
    } else if (user_id !== undefined) {
      queryParams = `?user_id=${user_id}`;
    } else if (is_approved !== undefined) {
      queryParams = `?is_approved=${is_approved}`;
    }
    return axios.get<EventDescriptionResponse>(
      `${envConfig?.remoteApi}/admin/EventDescriptionList${queryParams}`,
      {
        headers,
      }
    );
  }
);


export const loadAddEventDescription = createAsyncThunk(
  "addEventDescription/loadAddEventDescription",
  async (args: FormData | EventDescriptionType, thunkAPI) => {
    return axios.post<EventDescriptionResponse>(
      `${envConfig?.remoteApi}/admin/createEventsDescription`,
      args,
      {
        headers: {
          ...headers,
          "Content-Type": "multipart/form-data",
        },
        data: args,
      }
    );
  }
);

export const loadUpdateEventDescription = createAsyncThunk(
  "updateEventDescription/loadUpdateEventDescription",
  async (args: FormData | EventDescriptionType, thunkAPI) => {
    return axios.post<EventDescriptionResponse>(
      `${envConfig?.remoteApi}/admin/updateEventsDescription`,
      args,
      {
        headers: {
          ...headers,
          "Content-Type": "multipart/form-data",
        },
        data: args,
      }
    );
  }
);

export const loadEventDescriptionDetail = createAsyncThunk(
  "detailEventDescription/loadEventDescriptionDetail",
  async (args: string, thunkAPI) => {
    return axios.get<EventDescriptionDetailResponse>(
      `${envConfig?.remoteApi}/admin/event/${args}`,
      {
        headers,
      }
    );
  }
);

export const loadEditEventDescription = createAsyncThunk(
  "editEventDescription/loadEditEventDescription",
  async (args: EventDescriptionType, thunkAPI) => {
    return axios.post<EventDescriptionResponse>(
      `${envConfig?.remoteApi}/admin/updateEventsDescription`,
      args,
      {
        headers: {
          ...headers,
          "Content-Type": "multipart/form-data",
        }
      }
    );
  }
);

export const loadEventDescriptionDelete = createAsyncThunk(
  "deleteEventDescription/loadEventDescriptionDelete",
  async (args: number, thunkAPI) => {
    return axios.delete<EventDescriptionResponse>(
      `${envConfig?.remoteApi}/admin/deleteEventDescription/${args}`,
      {
        headers,
      }
    );
  }
);

export const loadEventDescriptionStatus = createAsyncThunk(
  "statusEventDescription/loadEventDescriptionStatus",
  async (args: { event_id: number; description_status: string, reason: string}, thunkAPI) => {
    return axios.post<EventDescriptionResponse>(
      `${envConfig?.remoteApi}/admin/changeEventStatus`,
      args,
      {
        headers,
      }
    );
  }
);

export const EventDescriptionSlice = createSlice({
  name: "EventDescription",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // loadGetEventDescription
      .addCase(loadGetEventDescription.pending, (state): EventDescriptionState => {
        return {
          ...state,
          getEventDescription: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadGetEventDescription.fulfilled, (state, action): EventDescriptionState => {
        return {
          ...state,
          getEventDescription: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadGetEventDescription.rejected, (state, action): EventDescriptionState => {
        return {
          ...state,
          getEventDescription: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadAddEventDescription
      .addCase(loadAddEventDescription.pending, (state): EventDescriptionState => {
        return {
          ...state,
          addEventDescription: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadAddEventDescription.fulfilled, (state, action): EventDescriptionState => {
        return {
          ...state,
          addEventDescription: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadAddEventDescription.rejected, (state, action): EventDescriptionState => {
        return {
          ...state,
          addEventDescription: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadEventDescriptionDetail
      .addCase(loadEventDescriptionDetail.pending, (state): EventDescriptionState => {
        return {
          ...state,
          detailEventDescription: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadEventDescriptionDetail.fulfilled, (state, action): EventDescriptionState => {
        return {
          ...state,
          detailEventDescription: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadEventDescriptionDetail.rejected, (state, action): EventDescriptionState => {
        return {
          ...state,
          detailEventDescription: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadEditEventDescription
      .addCase(loadEditEventDescription.pending, (state): EventDescriptionState => {
        return {
          ...state,
          editEventDescription: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadEditEventDescription.fulfilled, (state, action): EventDescriptionState => {
        return {
          ...state,
          editEventDescription: {
            currentState: AsyncStateType.FULFILLED,
            // response: action.payload,
          },
        };
      })
      .addCase(loadEditEventDescription.rejected, (state, action): EventDescriptionState => {
        return {
          ...state,
          editEventDescription: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadEventDescriptionDelete
      .addCase(loadEventDescriptionDelete.pending, (state): EventDescriptionState => {
        return {
          ...state,
          deleteEventDescription: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadEventDescriptionDelete.fulfilled, (state, action): EventDescriptionState => {
        return {
          ...state,
          deleteEventDescription: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadEventDescriptionDelete.rejected, (state, action): EventDescriptionState => {
        return {
          ...state,
          deleteEventDescription: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadEventDescriptionStatus
      .addCase(loadEventDescriptionStatus.pending, (state): EventDescriptionState => {
        return {
          ...state,
          statusEventDescription: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadEventDescriptionStatus.fulfilled, (state, action): EventDescriptionState => {
        return {
          ...state,
          statusEventDescription: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadEventDescriptionStatus.rejected, (state, action): EventDescriptionState => {
        return {
          ...state,
          statusEventDescription: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      });
  },
});

// export the combined reducers
export const EventDescriptionReducer = EventDescriptionSlice.reducer;

// Other code such as selectors can use the imported `RootState` type
export const selectEventDescription = (state: RootState) => state.eventDescriptionReducer;
