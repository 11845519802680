import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "src/stores";
import { AsyncResponseState, AsyncStateType, Headers } from "src/stores/shares/utility";
import { ReferenceDetailResponse, ReferenceResponse } from "./types";
import { envConfig } from "src/config/envConfig";
const headers = Headers();

// Define a type for the slice state
export interface ReferenceState {
  getReferenceList: AsyncResponseState<AxiosResponse<ReferenceResponse>>;
  detailReference: AsyncResponseState<AxiosResponse<ReferenceDetailResponse>>;
  deleteReference: AsyncResponseState<AxiosResponse<ReferenceDetailResponse>>;
}

// Define the initial state using that type
const initialState: ReferenceState = {
  getReferenceList: {
    currentState: AsyncStateType.INITIAL,
  },
  detailReference: {
    currentState: AsyncStateType.INITIAL,
  },
  deleteReference: {
    currentState: AsyncStateType.INITIAL,
  },
};

export const loadReference = createAsyncThunk("reference/loadReference", async (args, thunkAPI) => {
  return axios.post<ReferenceResponse>(
    `${envConfig?.remoteApi}/admin2/Referance`,
    {
      column_name: "id",
      draw: 1,
      length: 25,
      order: "desc",
      search: "",
      start: 0,
    },
    { headers }
  );
});

export const loadDeleteReference = createAsyncThunk(
  "reference/loadDeleteReference",
  async (args: number, thunkAPI) => {
    return axios.post<ReferenceDetailResponse>(
      `${envConfig?.remoteApi}/admin/deleteReferance`,
      {
        id: args,
      },
      { headers }
    );
  }
);

export const loadDetailReference = createAsyncThunk(
  "reference/loadDetailReference",
  async (args: number, thunkAPI) => {
    return axios.post<ReferenceDetailResponse>(
      `${envConfig?.remoteApi}/admin2/RetrieveReferenceDetails`,
      {
        id: args,
      },
      { headers }
    );
  }
);

export const ReferenceSlice = createSlice({
  name: "reference",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // loadReference
      .addCase(loadReference.pending, (state): ReferenceState => {
        return {
          ...state,
          getReferenceList: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadReference.fulfilled, (state, action): ReferenceState => {
        return {
          ...state,
          getReferenceList: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadReference.rejected, (state, action): ReferenceState => {
        return {
          ...state,
          getReferenceList: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadDetailReference
      .addCase(loadDetailReference.pending, (state): ReferenceState => {
        return {
          ...state,
          detailReference: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadDetailReference.fulfilled, (state, action): ReferenceState => {
        return {
          ...state,
          detailReference: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadDetailReference.rejected, (state, action): ReferenceState => {
        return {
          ...state,
          detailReference: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadDeleteReference
      .addCase(loadDeleteReference.pending, (state): ReferenceState => {
        return {
          ...state,
          deleteReference: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadDeleteReference.fulfilled, (state, action): ReferenceState => {
        return {
          ...state,
          deleteReference: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadDeleteReference.rejected, (state, action): ReferenceState => {
        return {
          ...state,
          deleteReference: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      });
  },
});

// export the combined reducers
export const ReferenceReducer = ReferenceSlice.reducer;

// Other code such as selectors can use the imported `RootState` type
export const selectReference = (state: RootState) => state.referenceReducer;
