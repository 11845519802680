import { Alert, AlertTitle, Avatar, Backdrop } from "@mui/material";
import { makeStyles } from "@mui/styles";
interface Props {
  loading: boolean;
  error?: string;
}
export const FullScreenLoader = ({ loading, error }: Props) => {
  const classes = useClasses();
  return (
    <>
      {loading && (
        <Backdrop open={loading ? loading : false} className={classes.screenLoader}>
          <Avatar src="/static/images/site_logo1.png" sx={{ width: "auto" }} variant="rounded" />
        </Backdrop>
      )}
      {error && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {error}
        </Alert>
      )}
    </>
  );
};

let useClasses = makeStyles({
  screenLoader: {
    backgroundColor: "rgba(255,255,255,0.80) !important",
    zIndex: 1199,
  },
  signInBox: {
    maxWidth: "400px",
    margin: "1.25rem auto",
  },
  logoBox: {
    margin: "1.5rem 3rem",
  },
});
