import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "src/stores";
import { AsyncResponseState, AsyncStateType, Headers } from "src/stores/shares/utility";
import {
  EventCoordinatorsDetailResponse,
  EventCoordinatorsResponse,
  EventCoordinatorsType,
} from "./types";
import { envConfig } from "src/config/envConfig";
const headers = Headers();

// Define a type for the slice state
export interface EventCoordinatorsState {
  getEventCoordinatorsDetail: AsyncResponseState<AxiosResponse<EventCoordinatorsDetailResponse>>;
  getEventCoordinators: AsyncResponseState<AxiosResponse<EventCoordinatorsResponse>>;
  addEventCoordinators: AsyncResponseState<AxiosResponse<EventCoordinatorsResponse>>;
  editEventCoordinatorsDetail: AsyncResponseState<AxiosResponse<EventCoordinatorsResponse>>;
  deleteEventCoordinators: AsyncResponseState<AxiosResponse<EventCoordinatorsDetailResponse>>;
}

// Define the initial state using that type
const initialState: EventCoordinatorsState = {
  getEventCoordinatorsDetail: {
    currentState: AsyncStateType.INITIAL,
  },
  getEventCoordinators: {
    currentState: AsyncStateType.INITIAL,
  },
  addEventCoordinators: {
    currentState: AsyncStateType.INITIAL,
  },
  editEventCoordinatorsDetail: {
    currentState: AsyncStateType.INITIAL,
  },
  deleteEventCoordinators: {
    currentState: AsyncStateType.INITIAL,
  },
};

export const loadGetEventCoordinatorsDetail = createAsyncThunk(
  "getEventCoordinatorsDetail/loadGetEventCoordinatorsDetail",
  async (args: number, thunkAPI) => {
    return axios.get<EventCoordinatorsDetailResponse>(
      `${envConfig?.remoteApi}/admin/getEventCoordinator/${args}`,
      { headers }
    );
  }
);

export const loadGetEventCoordinators = createAsyncThunk(
  "getListEventCoordinators/loadGetEventCoordinators",
  async (args, thunkAPI) => {
    return axios.get<EventCoordinatorsResponse>(
      `${envConfig?.remoteApi}/admin/eventCoordinatorList`,
      { headers }
    );
  }
);

export const loadAddEventCoordinators = createAsyncThunk(
  "addEventCoordinators/loadAddEventCoordinators",
  async (args: FormData | EventCoordinatorsType, thunkAPI) => {
      return axios.post<EventCoordinatorsResponse>(
        `${envConfig?.remoteApi}/admin/createEventCoordinator`,
        args,
        {
          headers: {
            ...headers,
            "Content-Type": "multipart/form-data",
          },
          data: args,
        }
      );
    }
);

export const loadEditEventCoordinatorsDetail = createAsyncThunk(
  "getEventCoordinatorsDetail/loadEditEventCoordinatorsDetail",
  async (args: FormData | EventCoordinatorsType, thunkAPI) => {
      return axios.post<EventCoordinatorsDetailResponse>(
        `${envConfig?.remoteApi}/admin/updateEventCoordinator`,
        args,
        {
          headers: {
            ...headers,
            "Content-Type": "multipart/form-data",
          },
          data: args,
        }
      );
    }
);

export const loadDeleteEventCoordinators = createAsyncThunk(
  "deleteEventCoordinators/loadDeleteEventCoordinators",
  async (args: number, thunkAPI) => {
    return axios.delete<EventCoordinatorsDetailResponse>(
      `${envConfig?.remoteApi}/admin/deleteEventCoordinator/${args}`,
      {
        headers,
      }
    );
  }
);

export const EventCoordinatorsSlice = createSlice({
  name: "event-coordinators",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // loadEventCoordinatorsDetail
      .addCase(loadGetEventCoordinatorsDetail.pending, (state): EventCoordinatorsState => {
        return {
          ...state,
          getEventCoordinatorsDetail: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(
        loadGetEventCoordinatorsDetail.fulfilled,
        (state, action): EventCoordinatorsState => {
          return {
            ...state,
            getEventCoordinatorsDetail: {
              currentState: AsyncStateType.FULFILLED,
              response: action.payload,
            },
          };
        }
      )
      .addCase(loadGetEventCoordinatorsDetail.rejected, (state, action): EventCoordinatorsState => {
        return {
          ...state,
          getEventCoordinatorsDetail: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadEventCoordinators
      .addCase(loadGetEventCoordinators.pending, (state): EventCoordinatorsState => {
        return {
          ...state,
          getEventCoordinators: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadGetEventCoordinators.fulfilled, (state, action): EventCoordinatorsState => {
        return {
          ...state,
          getEventCoordinators: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadGetEventCoordinators.rejected, (state, action): EventCoordinatorsState => {
        return {
          ...state,
          getEventCoordinators: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadAddEventCoordinators
      .addCase(loadAddEventCoordinators.pending, (state): EventCoordinatorsState => {
        return {
          ...state,
          addEventCoordinators: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadAddEventCoordinators.fulfilled, (state, action): EventCoordinatorsState => {
        return {
          ...state,
          addEventCoordinators: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadAddEventCoordinators.rejected, (state, action): EventCoordinatorsState => {
        return {
          ...state,
          addEventCoordinators: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })

      // loadDeleteEvent
      .addCase(loadDeleteEventCoordinators.pending, (state): EventCoordinatorsState => {
        return {
          ...state,
          deleteEventCoordinators: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadDeleteEventCoordinators.fulfilled, (state, action): EventCoordinatorsState => {
        return {
          ...state,
          deleteEventCoordinators: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadDeleteEventCoordinators.rejected, (state, action): EventCoordinatorsState => {
        return {
          ...state,
          deleteEventCoordinators: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      });
  },
});

// export the combined reducers
export const EventCoordinatorsReducer = EventCoordinatorsSlice.reducer;

// Other code such as selectors can use the imported `RootState` type
export const selectEventCoordinators = (state: RootState) => state.eventCoordinatorsReducer;
