import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "src/stores";
import { AsyncResponseState, AsyncStateType, Headers } from "src/stores/shares/utility";
import { AssignUserResponse } from "./types";
import { envConfig } from "src/config/envConfig";
const headers = Headers();

// Define a type for the slice state
export interface AssignUserState extends AsyncResponseState<AxiosResponse<AssignUserResponse>> {}

// Define the initial state using that type
const initialState: AssignUserState = {
  currentState: AsyncStateType.INITIAL,
};

export const loadAssignUser = createAsyncThunk(
  "assignUser/loadAssignUser",
  async (userId: string, thunkAPI) => {
    return axios.post<AssignUserResponse>(
      `${envConfig?.remoteApi}/admin2/SelectedUsers`,
      {
        user_id: userId,
      },
      { headers }
    );
  }
);

export const AssignUserSlice = createSlice({
  name: "assignUser",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadAssignUser.pending, (state): AssignUserState => {
        return {
          ...state,
          currentState: AsyncStateType.PENDING,
        };
      })
      .addCase(loadAssignUser.fulfilled, (state, action): AssignUserState => {
        return {
          ...state,
          currentState: AsyncStateType.FULFILLED,
          response: action.payload,
        };
      })
      .addCase(loadAssignUser.rejected, (state, action): AssignUserState => {
        return {
          ...state,
          currentState: AsyncStateType.REJECTED,
          error: `error: ${action.error.code} ${action.error.message}`,
        };
      });
  },
});

// export the combined reducers
export const AssignUserReducer = AssignUserSlice.reducer;

// Other code such as selectors can use the imported `RootState` type
export const selectAssignUser = (state: RootState) => state.assignUserReducer;
