import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "src/stores";
import { AsyncResponseState, AsyncStateType, Headers } from "src/stores/shares/utility";
import { MatchDetailResponse } from "./types";
import { envConfig } from "src/config/envConfig";
import { UserAndCurrentUserType } from "src/pages/paged-response";
const headers = Headers();

// Define a type for the slice state
export interface MatchDetailState extends AsyncResponseState<AxiosResponse<MatchDetailResponse>> {}

// Define the initial state using that type
const initialState: MatchDetailState = {
  currentState: AsyncStateType.INITIAL,
};

export const loadMatchDetail = createAsyncThunk(
  "matchDetail/loadMatchDetail",
  async (UserAndCurrentUser: UserAndCurrentUserType, thunkAPI) => {
    return axios.post<MatchDetailResponse>(
      `${envConfig?.remoteApi}/admin2/getMatchDetails`,
      {
        current_assign_user: UserAndCurrentUser?.current_assign_user,
        user_id: UserAndCurrentUser?.user_id,
      },
      { headers }
    );
  }
);

export const MatchDetailSlice = createSlice({
  name: "matchDetail",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadMatchDetail.pending, (state): MatchDetailState => {
        return {
          ...state,
          currentState: AsyncStateType.PENDING,
        };
      })
      .addCase(loadMatchDetail.fulfilled, (state, action): MatchDetailState => {
        return {
          ...state,
          currentState: AsyncStateType.FULFILLED,
          response: action.payload,
        };
      })
      .addCase(loadMatchDetail.rejected, (state, action): MatchDetailState => {
        return {
          ...state,
          currentState: AsyncStateType.REJECTED,
          error: `error: ${action.error.code} ${action.error.message}`,
        };
      });
  },
});

// export the combined reducers
export const MatchDetailReducer = MatchDetailSlice.reducer;

// Other code such as selectors can use the imported `RootState` type
export const selectMatchDetail = (state: RootState) => state.matchDetailReducer;
