import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { envConfig } from "src/config/envConfig";
import { Headers } from "src/stores/shares/utility";

const headers = Headers();

const apiClient = () => {
    const baseURL = envConfig?.remoteApi;
    const headers = {};
    return axios.create({
        baseURL,
        headers,
    });
};

export const loadEventDetail = createAsyncThunk(
    "loadEventDetail",
    async (_request: any, { dispatch }) => {
        const { getEventId, setEventDetail, setTicketLimit } = _request;
        dispatch(setLoading(true));
        await apiClient()
            .get(`admin/event/${getEventId}`)
            .then(async (response) => {
                console.log('response 7777: ', response);
                dispatch(setLoading(false))
                dispatch(getEventDet(response?.data?.result?.data))
                setEventDetail(response?.data?.result?.data)
                setTicketLimit(response?.data?.result?.data?.available_ticket)
            })
            .catch((error) => {
                console.log('error: ', error);
                dispatch(setLoading(false))
            });
    }
);

export const getCheckEventTicket = createAsyncThunk(
    "getCheckEventTicket",
    async (_request: any, { dispatch }) => {
        const { getEventId, setCheckTicketAvl } = _request;
        dispatch(setLoading(true));
        await apiClient()
            .get(`useraccess/checkEventTicketAvailable?event_id=${getEventId}`)
            .then(async (response) => {
                dispatch(setLoading(false))
                setCheckTicketAvl(response?.data?.result?.data)
            })
            .catch((error) => {
                console.log('error: ', error);
                dispatch(setLoading(false))
            });
    }
);

export const checkParticipateEmail = createAsyncThunk(
    "checkParticipateEmail",
    async (_request: any, { dispatch }) => {
        const { email, event_id, setCheckEmail } = _request;
        dispatch(setLoading(true))
        await apiClient()
            .get(`useraccess/checkUserParticipant?event_id=${event_id}&email=${email}`,)
            .then(async (response) => {
                if (response?.data && response?.data?.message === "User already participated in this event.") {
                    setCheckEmail(false)
                } else {
                    setCheckEmail(true)
                }
            })
            .catch((error) => {
                console.log('error: ', error);
                dispatch(setLoading(false))
            });
    }
);

export const createPaymentIntent = createAsyncThunk(
    "createPaymentIntent",
    async (_request: any, { dispatch }) => {
        const { amount, navigate, setJoinEvent, currency, payment_method_types } = _request;
        dispatch(setLoading(true))
        await apiClient()
            .post("useraccess/createPaymentIntent", { amount: amount, currency: currency, payment_method: payment_method_types })
            .then(async (response) => {
                dispatch(setLoading(false))
                if (response?.data?.data?.client_secret) {
                    // setClientSecret(response?.data?.data?.client_secret);
                    //set secretkey when redirect to payment page
                    dispatch(getStripeKey(response?.data?.data?.client_secret))
                    //set join event data when redirect to payment page
                    // dispatch(setJointEventData(setJoinEvent))
                    localStorage.setItem('joinEventData', JSON.stringify(setJoinEvent))
                    let getPaymentIntentData = response?.data?.data

                    let getPaymentData = {
                        transaction_id: getPaymentIntentData?.id,
                        amount: amount,
                        quantity: setJoinEvent?.quantity,
                        payment_type: "Card"
                    }
                    localStorage.setItem("paymentDetail", JSON.stringify(getPaymentData))
                    navigate("/admin/payment")
                }
            })
            .catch((error) => {
                console.log('error: ', error);
                dispatch(setLoading(false))
            });
    }
);

export const joinEvent = createAsyncThunk(
    "joinEvent",
    async (_request: any, { dispatch }) => {
        // const { event_id, name, email, mobile, guest_users } = _request;
        dispatch(setLoading(true))

        const getRandomNum = (length: any) => {
            var randomNum =
                (Math.pow(10, length).toString().slice(length - 1) +
                    Math.floor((Math.random() * Math.pow(10, length)) + 1).toString()).slice(-length);
            return randomNum;
        }

        let randomNumber = getRandomNum(8)

        await apiClient()
            .post("useraccess/joinEvent", { ..._request })
            .then(async (response) => {
                dispatch(setLoading(false))
                let getJntData = response?.data?.result?.data
                dispatch(setJoinEventResponse(getJntData))
                let paymentDetail: any = JSON.parse(localStorage.getItem('paymentDetail') || '')

                if (getJntData[0]) {
                    let getData = getJntData[0]
                    let storeData: any = {
                        event_id: getData?.event_id,
                        guest_user_id: getData?.purchase_by,
                        transaction_id: paymentDetail?.transaction_id,
                        order_number: randomNumber,
                        payment_type: paymentDetail?.payment_type,
                        amount: paymentDetail?.amount,
                        quantity: paymentDetail?.quantity,
                        status: "Succeeded"
                    }
                    dispatch(storeOrderDetail(storeData))
                }
            })
            .catch((error) => {
                console.log('error: ', error);
                dispatch(setLoading(false))
            });
    }
);

export const storeOrderDetail = createAsyncThunk(
    "storeOrderDetail",
    async (_request: any, { dispatch }) => {
        dispatch(setLoading(true))
        await apiClient()
            .post("useraccess/storeOrderDetail", { ..._request })
            .then(async (response) => {
                dispatch(setLoading(false))
                if (response) {
                    console.log('response: ', response);
                }
            })
            .catch((error) => {
                console.log('error: ', error);
                dispatch(setLoading(false))
            });
    }
);

export const eventWaitingList = createAsyncThunk(
    "eventWaitingList",
    async (_request: any, { dispatch }) => {
        dispatch(setLoading(true))
        await apiClient()
            .post("useraccess/eventWaitingList", { ..._request })
            .then(async (response) => {
                dispatch(setLoading(false))
                if (response) {
                    dispatch(setWaitingListMail(response?.data))
                }
            })
            .catch((error) => {
                console.log('error: ', error);
                dispatch(setLoading(false))
            });
    }
);

// -----------------------------------------------------------



const initialState = {
    loading: false,
    eventDetailData: "",
    stripeKey: "",
    joinEventData: "",
    checkEmail: "",
    waitingListMail: {},
    joinEventResponse:null
};

const eventSlice = createSlice({
    name: 'eventDetail',
    initialState,
    reducers: {
        setLoading: (state, action) => ({
            ...state,
            loading: action.payload,
        }),
        getEventDet: (state, action) => ({
            ...state,
            eventDetailData: action.payload,
        }),
        getStripeKey: (state, action) => ({
            ...state,
            stripeKey: action.payload
        }),

        setJointEventData: (state, action) => ({
            ...state,
            joinEventData: action.payload
        }),

        setEmailCheck: (state, action) => ({
            ...state,
            checkEmail: action.payload
        }),

        setWaitingListMail: (state, action) => ({
            ...state,
            waitingListMail: {
                data: action.payload,
                status: true
            }
        }),
        setJoinEventResponse: (state, action) => ({
            ...state,
            joinEventResponse:action.payload
        })
    },
})

// Action creators are generated for each case reducer function
export const { getEventDet, getStripeKey, setLoading, setJointEventData, setEmailCheck, setWaitingListMail, setJoinEventResponse } = eventSlice.actions


export const EventDetailReducer = eventSlice.reducer;

export const selectEventDetail = (state: any) => state.eventDetailReducer;