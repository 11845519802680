import { makeStyles } from "@mui/styles";

export const sidebarStyle = makeStyles({
  anchorTag: {
    textDecoration: "none",
    color: "white",
    paddingLeft: "24px",
    "& .material-icons": {
      fontSize: "inherit",
      fontWeight: "700",
    },
    "& div>span": {
      fontSize: "14px",
      fontWeight: "700",
    },
    "&:hover": {
      background: "#9BC4C4",
      color: "black",
      transition: "all 0.5s ease",
      "& .material-icons": {
        color: "black",
      },
    },
  },
  activeMenu: {
    backgroundColor: "white",
    color: "black",
    paddingLeft: "24px",
    "& .material-icons": {
      color: "black",
      fontSize: "inherit",
      fontWeight: "700",
    },
    "& div>span": {
      fontSize: "14px",
      fontWeight: "700",
    },
  },
  anchorUserTag: {
    textDecoration: "none",
    color: "#000",
    // backgroundColor: "lightgray",
    background:"#9BC4C4",
    boxShadow: "unset",
    borderRadius: "0 !important",
    paddingLeft: "4px",
    "& .material-icons": {
      fontSize: "inherit",
      fontWeight: "700",
    },
    "& div>span": {
      fontSize: "14px",
      fontWeight: "700",
    },
    "&:hover": {
      background: "#9BC4C4",
      color: "#fff",
      "& .material-icons": {
        color: "#fff",
      },
    },
  },
  activeUserMenu: {
    backgroundColor: "#9BC4C4",
    borderLeft: "4px solid #19aa8d",
    borderRadius: "0 !important",
    color: "#fff",
    "& .material-icons": {
      color: "#fff",
      fontSize: "inherit",
      fontWeight: "700",
    },
    "& div>span": {
      fontSize: "14px",
      fontWeight: "700",
    },
  },
  sidebarIcon: {
    color: `white`,
  },
  sidebarList: {
    borderColor: "#9BC4C4",
    // backgroundColor: "lightgray",
    background:"#9BC4C4",
    overflow: "hidden",
    "& a": {
      textDecoration: "none",
      "& li": {
        padding: "8px 8px 8px 24px",
      },
    },
  },
});
