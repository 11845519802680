import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CardMedia,
  Drawer,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { MenuItemDefinition } from "src/layout/interface";
import { sidebarStyle } from "./pageStyle";
import Sidemenu from "src/layout/menuitems.json";
import { Link, useLocation } from "react-router-dom";
import i18Next from "src/i18Next/config";
import { useTranslation } from "react-i18next";
import { getUserRole, getUserId } from "src/stores/shares/utility";

export const Sidebar = () => {
  const classes = sidebarStyle();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const [language] = useState<string>("no");
  const [userRole, setUserRole] = useState<number>(0);
  const [userId, setUserId] = useState<number>(0);
  const [userExpanded, setUserExpanded] = useState<boolean>(false);

  useEffect(() => {
    i18Next.changeLanguage(language);
    const storedUserRole = getUserRole();
    if (storedUserRole) {
      setUserRole(parseInt(storedUserRole));
    }
    const storedUserId = getUserId();
    if (storedUserId) {
      setUserId(parseInt(storedUserRole));
    }
  }, [language]);

  const mainBoxStyle = {
    width: {
      sm: "220px",
    },
    flexShrink: 0,
  };

  const mainDrawerBoxStyle1 = {
    display: { xs: "none", sm: "block" },
    "& .MuiDrawer-paper": {
      boxSizing: "border-box",
      flexShrink: 0,
      whiteSpace: "nowrap",
      width: "220px",
      transition: "ease",
      overflowX: "hidden",
      border: "unset",
    },
  };

  return (
    <Box component="nav" sx={{ ...mainBoxStyle }}>
      <Drawer variant="permanent" sx={{ ...mainDrawerBoxStyle1 }} open>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            pt: "4px",
            background:"#9BC4C4",
          }}
        >
          <CardMedia
            sx={{ height: 60, width: "60%" }}
            image="/static/images/site_logo1.png"
            title="Duoo Events"
          />
        </Box>
        <Box sx={{ background:"#9BC4C4", height: "inherit" }}>
          <List className={classes.sidebarList} sx={{ pt: 0 }}>
            {Sidemenu.sidebar.map((menu: MenuItemDefinition, index) => (
              (userRole !== 2 || (menu.label !== "Event Coordinator" && menu.label !== "Søk")) && (
                menu?.children ? (
                  <Accordion
                    key={menu?.label}
                    sx={{
                      backgroundColor: userExpanded ? "#9BC4C4" : "lightgray",
                      color: userExpanded ? "#fff" : "#000",
                      boxShadow: "unset",
                    }}
                    expanded={userExpanded}
                    onChange={() => setUserExpanded(!userExpanded)}
                    className={userExpanded ? classes.activeUserMenu : classes.anchorUserTag}
                  >
                    <AccordionSummary
                      sx={{ padding: "0 24px" }}
                      expandIcon={<Icon>expand_more</Icon>}
                    >
                      <Typography
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "14px",
                          fontWeight: "700",
                          ml: "-4px",
                        }}
                        component="h6"
                      >
                        <Icon sx={{ mr: 1 }}>{menu.icon}</Icon>
                        {t(`Sidebar.${[index]}.label`)}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {menu?.children?.map((submenu: MenuItemDefinition, subindex) => (
                        <Link key={`${submenu.to}_link`} to={`/admin/${submenu.to}`}>
                          <ListItem
                            className={userExpanded ? classes.activeMenu : classes.anchorTag}
                            key={`${submenu.to}_link_list_item`}
                          >
                            <ListItemIcon sx={{ minWidth: "24px" }}>
                              <Icon className={classes.sidebarIcon}>{submenu.icon}</Icon>
                            </ListItemIcon>
                            <ListItemText
                              primary={t(`Sidebar.${[index]}.children.${[subindex]}.label`)}
                            />
                          </ListItem>
                        </Link>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                ) : (
                  <Link
                    key={`${menu.to}_link`}
                    to={`/admin/${menu.to}`}
                    onClick={() => setUserExpanded(false)}
                  >
                    <ListItem
                      className={
                        pathname === `/admin/${menu.to}` ? classes.activeMenu : classes.anchorTag
                      }
                      key={`${menu.to}_link_list_item`}
                    >
                      <ListItemIcon sx={{ minWidth: "24px" }}>
                        <Icon className={classes.sidebarIcon}>{menu.icon}</Icon>
                      </ListItemIcon>
                      <ListItemText primary={t(`Sidebar.${[index]}.label`)} />
                    </ListItem>
                  </Link>
                )
              )
            ))}
          </List>
        </Box>
      </Drawer>
    </Box>
  );
};
