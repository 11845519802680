import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "src/stores";
import { AsyncResponseState, AsyncStateType, Headers } from "src/stores/shares/utility";
import {
  AddNotePayloads,
  AddNoteResponse,
  AssignMatchmakerResponse,
  AssignMatchmakerType,
  CallLogResponse,
  DeleteCallLogType,
  GeneratePasswordResponse,
  GenerateUserPassword,
  MatchmakerListResponse,
  ProfileStatusResponse,
  ProfileStatusType,
  ReportAbuseResponse,
  UserListingResponse,
} from "./types";
import { envConfig } from "src/config/envConfig";
const headers = Headers();

// Define a type for the slice state
export interface UserListingState {
  getUserListing: AsyncResponseState<AxiosResponse<UserListingResponse>>;
  getMatchmakerList: AsyncResponseState<AxiosResponse<MatchmakerListResponse>>;
  assignMatchmaker: AsyncResponseState<AxiosResponse<AssignMatchmakerResponse>>;
  profileStatus: AsyncResponseState<AxiosResponse<ProfileStatusResponse>>;
  generatePassword: AsyncResponseState<AxiosResponse<GeneratePasswordResponse>>;
  deleteProfile: AsyncResponseState<AxiosResponse<GeneratePasswordResponse>>;
  getCallLog: AsyncResponseState<AxiosResponse<CallLogResponse>>;
  deleteCallLog: AsyncResponseState<AxiosResponse<CallLogResponse>>;
  addNotes: AsyncResponseState<AxiosResponse<AddNoteResponse>>;
  getReportAbuse: AsyncResponseState<AxiosResponse<ReportAbuseResponse>>;
}

// Define the initial state using that type
const initialState: UserListingState = {
  getUserListing: {
    currentState: AsyncStateType.INITIAL,
  },
  getMatchmakerList: {
    currentState: AsyncStateType.INITIAL,
  },
  assignMatchmaker: {
    currentState: AsyncStateType.INITIAL,
  },
  profileStatus: {
    currentState: AsyncStateType.INITIAL,
  },
  generatePassword: {
    currentState: AsyncStateType.INITIAL,
  },
  deleteProfile: {
    currentState: AsyncStateType.INITIAL,
  },
  getCallLog: {
    currentState: AsyncStateType.INITIAL,
  },
  deleteCallLog: {
    currentState: AsyncStateType.INITIAL,
  },
  addNotes: {
    currentState: AsyncStateType.INITIAL,
  },
  getReportAbuse: {
    currentState: AsyncStateType.INITIAL,
  },
};

export const loadUserListing = createAsyncThunk(
  "userListing/loadUserListing",
  async (listingtype: string, thunkAPI) => {
    return axios.post<UserListingResponse>(
      `${envConfig?.remoteApi}/admin/UserListing`,
      {
        length: 200,
        start: 0,
        draw: 1,
        column_name: "user_id",
        order: "desc",
        search: "",
        listingtype: listingtype,
      },
      { headers }
    );
  }
);

export const loadMatchmakerList = createAsyncThunk(
  "userListing/loadMatchmakerList",
  async (args, thunkAPI) => {
    return axios.get<MatchmakerListResponse>(`${envConfig?.remoteApi}/admin2/matchmakerList`, {
      headers,
    });
  }
);

export const loadAssignMatchmaker = createAsyncThunk(
  "userListing/loadAssignMatchmaker",
  async (args: AssignMatchmakerType, thunkAPI) => {
    return axios.post<AssignMatchmakerResponse>(
      `${envConfig?.remoteApi}/admin2/assignMatchmaker`,
      args,
      { headers }
    );
  }
);

export const loadProfileStatus = createAsyncThunk(
  "userListing/loadProfileStatus",
  async (args: ProfileStatusType, thunkAPI) => {
    return axios.post<ProfileStatusResponse>(`${envConfig?.remoteApi}/admin/profile_status`, args, {
      headers,
    });
  }
);

export const loadGeneratePassword = createAsyncThunk(
  "userListing/loadGeneratePassword",
  async (args: GenerateUserPassword, thunkAPI) => {
    return axios.post<GeneratePasswordResponse>(
      `${envConfig?.remoteApi}/admin/GenerateUserPassword`,
      args,
      {
        headers,
      }
    );
  }
);

export const loadDeleteProfile = createAsyncThunk(
  "userListing/loadDeleteProfile",
  async (args: number, thunkAPI) => {
    return axios.get<GeneratePasswordResponse>(
      `${envConfig?.remoteApi}/admin/profileDelete?user_id=${args}`,
      {
        headers,
      }
    );
  }
);

export const loadAddNotes = createAsyncThunk(
  "userListing/loadAddNotes",
  async (args: AddNotePayloads, thunkAPI) => {
    return axios.post<AddNoteResponse>(`${envConfig?.remoteApi}/admin/addnote`, args, {
      headers,
    });
  }
);

export const loadGetCallLog = createAsyncThunk(
  "userListing/loadGetCallLog",
  async (args: number, thunkAPI) => {
    return axios.post<CallLogResponse>(
      `${envConfig?.remoteApi}/admin/getCallLog`,
      { id: args },
      {
        headers,
      }
    );
  }
);

export const loadDeleteCallLog = createAsyncThunk(
  "userListing/loadDeleteCallLog",
  async (args: DeleteCallLogType, thunkAPI) => {
    return axios.post<CallLogResponse>(`${envConfig?.remoteApi}/admin/deleteCallLog`, args, {
      headers,
    });
  }
);

export const loadGetReportAbuse = createAsyncThunk(
  "userListing/loadGetReportAbuse",
  async (args: number, thunkAPI) => {
    return axios.post<ReportAbuseResponse>(
      `${envConfig?.remoteApi}/admin/getreportAbuse`,
      { user_id: args },
      {
        headers,
      }
    );
  }
);

export const UserListingSlice = createSlice({
  name: "userListing",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // loadUserListing
      .addCase(loadUserListing.pending, (state): UserListingState => {
        return {
          ...state,
          getUserListing: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadUserListing.fulfilled, (state, action): UserListingState => {
        return {
          ...state,
          getUserListing: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadUserListing.rejected, (state, action): UserListingState => {
        return {
          ...state,
          getUserListing: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadMatchmakerList
      .addCase(loadMatchmakerList.pending, (state): UserListingState => {
        return {
          ...state,
          getMatchmakerList: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadMatchmakerList.fulfilled, (state, action): UserListingState => {
        return {
          ...state,
          getMatchmakerList: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadMatchmakerList.rejected, (state, action): UserListingState => {
        return {
          ...state,
          getMatchmakerList: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadAssignMatchmaker
      .addCase(loadAssignMatchmaker.pending, (state): UserListingState => {
        return {
          ...state,
          assignMatchmaker: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadAssignMatchmaker.fulfilled, (state, action): UserListingState => {
        return {
          ...state,
          assignMatchmaker: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadAssignMatchmaker.rejected, (state, action): UserListingState => {
        return {
          ...state,
          assignMatchmaker: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadProfileStatus
      .addCase(loadProfileStatus.pending, (state): UserListingState => {
        return {
          ...state,
          profileStatus: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadProfileStatus.fulfilled, (state, action): UserListingState => {
        return {
          ...state,
          profileStatus: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadProfileStatus.rejected, (state, action): UserListingState => {
        return {
          ...state,
          profileStatus: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadGeneratePassword
      .addCase(loadGeneratePassword.pending, (state): UserListingState => {
        return {
          ...state,
          generatePassword: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadGeneratePassword.fulfilled, (state, action): UserListingState => {
        return {
          ...state,
          generatePassword: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadGeneratePassword.rejected, (state, action): UserListingState => {
        return {
          ...state,
          generatePassword: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadDeleteProfile
      .addCase(loadDeleteProfile.pending, (state): UserListingState => {
        return {
          ...state,
          deleteProfile: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadDeleteProfile.fulfilled, (state, action): UserListingState => {
        return {
          ...state,
          deleteProfile: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadDeleteProfile.rejected, (state, action): UserListingState => {
        return {
          ...state,
          deleteProfile: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadAddNotes
      .addCase(loadAddNotes.pending, (state): UserListingState => {
        return {
          ...state,
          addNotes: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadAddNotes.fulfilled, (state, action): UserListingState => {
        return {
          ...state,
          addNotes: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadAddNotes.rejected, (state, action): UserListingState => {
        return {
          ...state,
          addNotes: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadGetCallLog
      .addCase(loadGetCallLog.pending, (state): UserListingState => {
        return {
          ...state,
          getCallLog: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadGetCallLog.fulfilled, (state, action): UserListingState => {
        return {
          ...state,
          getCallLog: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadGetCallLog.rejected, (state, action): UserListingState => {
        return {
          ...state,
          getCallLog: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadDeleteCallLog
      .addCase(loadDeleteCallLog.pending, (state): UserListingState => {
        return {
          ...state,
          deleteCallLog: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadDeleteCallLog.fulfilled, (state, action): UserListingState => {
        return {
          ...state,
          deleteCallLog: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadDeleteCallLog.rejected, (state, action): UserListingState => {
        return {
          ...state,
          deleteCallLog: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadGetReportAbuse
      .addCase(loadGetReportAbuse.pending, (state): UserListingState => {
        return {
          ...state,
          getReportAbuse: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadGetReportAbuse.fulfilled, (state, action): UserListingState => {
        return {
          ...state,
          getReportAbuse: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadGetReportAbuse.rejected, (state, action): UserListingState => {
        return {
          ...state,
          getReportAbuse: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      });
  },
});

// export the combined reducers
export const UserListingReducer = UserListingSlice.reducer;

// Other code such as selectors can use the imported `RootState` type
export const selectUserListing = (state: RootState) => state.userListingReducer;
