import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "src/stores";
import { AsyncResponseState, AsyncStateType, Headers } from "src/stores/shares/utility";
import { GetLocationResponse } from "./types";
import { envConfig } from "src/config/envConfig";
const headers = Headers();

// Define a type for the slice state
export interface GetLocationState extends AsyncResponseState<AxiosResponse<GetLocationResponse>> {}

// Define the initial state using that type
const initialState: GetLocationState = {
  currentState: AsyncStateType.INITIAL,
};

export const loadGetLocation = createAsyncThunk(
  "getLocation/loadGetLocation",
  async (args: string, thunkAPI) => {
    return axios.get<GetLocationResponse>(
      `${envConfig?.remoteApi}/general/GetPincodeLocation?postcode=${args}`,
      {
        headers,
      }
    );
  }
);

export const GetLocationSlice = createSlice({
  name: "getLocation",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadGetLocation.pending, (state): GetLocationState => {
        return {
          ...state,
          currentState: AsyncStateType.PENDING,
        };
      })
      .addCase(loadGetLocation.fulfilled, (state, action): GetLocationState => {
        return {
          ...state,
          currentState: AsyncStateType.FULFILLED,
          response: action.payload,
        };
      })
      .addCase(loadGetLocation.rejected, (state, action): GetLocationState => {
        return {
          ...state,
          currentState: AsyncStateType.REJECTED,
          error: `error: ${action.error.code} ${action.error.message}`,
        };
      });
  },
});

// export the combined reducers
export const GetLocationReducer = GetLocationSlice.reducer;

// Other code such as selectors can use the imported `RootState` type
export const selectGetLocation = (state: RootState) => state.getLocationReducer;
