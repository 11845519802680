import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "src/stores";
import { AsyncResponseState, AsyncStateType, Headers } from "src/stores/shares/utility";
import { EventContactDetailResponse, EventContactResponse, EventContactType } from "./types";
import { envConfig } from "src/config/envConfig";
const headers = Headers();

export interface EventContactState {
  getEventContact: AsyncResponseState<AxiosResponse<EventContactResponse>>;
  addEventContact: AsyncResponseState<AxiosResponse<EventContactResponse>>;
  detailEventContact: AsyncResponseState<AxiosResponse<EventContactDetailResponse>>;
  editEventContact: AsyncResponseState<AxiosResponse<EventContactResponse>>;
  deleteEventContact: AsyncResponseState<AxiosResponse<EventContactResponse>>;
}

// Define the initial state using that type

const initialState: EventContactState = {
  getEventContact: {
    currentState: AsyncStateType.INITIAL,
  },
  addEventContact: {
    currentState: AsyncStateType.INITIAL,
  },
  detailEventContact: {
    currentState: AsyncStateType.INITIAL,
  },
    editEventContact: {
      currentState: AsyncStateType.INITIAL,
    },
  deleteEventContact: {
    currentState: AsyncStateType.INITIAL,
  },
};

export const loadGetEventContact = createAsyncThunk(
  "getEventContact/loadGetEventContact",
  async (args: { user_id?: string }, thunkAPI) => {
    const params = args.user_id ? `?user_id=${args.user_id}` : "";
    return axios.get<EventContactResponse>(
      `${envConfig?.remoteApi}/admin/eventContactList${params}`,
      {
        headers,
      }
    );
  }
);

export const loadEventContactDetail = createAsyncThunk(
  "detailEventContact/loadEventContactDetail",
  async (args: number, thunkAPI) => {
    return axios.get<EventContactDetailResponse>(
      `${envConfig?.remoteApi}/admin/getEventContact/${args}`,
      {
        headers,
      }
    );
  }
);

export const loadEventContactDelete = createAsyncThunk(
  "deleteEventContact/loadEventContactDelete",
  async (args: number, thunkAPI) => {
    return axios.delete<EventContactResponse>(
      `${envConfig?.remoteApi}/admin/deleteEventContact/${args}`,
      {
        headers,
      }
    );
  }
);

export const loadAddEventContact = createAsyncThunk(
  "addEventContact/loadAddEventContact",
  async (args: EventContactType, thunkAPI) => {
    return axios.post<EventContactResponse>(
      `${envConfig?.remoteApi}/admin/createEventContact`,
      args,
      {
        headers,
      }
    );
  }
);

export const loadEditEventContact = createAsyncThunk(
  "editEventContact/loadEditEventDescription",
  async (args: EventContactType, thunkAPI) => {
    return axios.post<EventContactResponse>(
      `${envConfig?.remoteApi}/admin/updateEventContact`,
      args,
      {
        headers,
      }
    );
  }
);

export const EventContactSlice = createSlice({
  name: "EventContact",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // loadGetEventContact
      .addCase(loadGetEventContact.pending, (state): EventContactState => {
        return {
          ...state,
          getEventContact: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadGetEventContact.fulfilled, (state, action): EventContactState => {
        return {
          ...state,
          getEventContact: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadGetEventContact.rejected, (state, action): EventContactState => {
        return {
          ...state,
          getEventContact: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadEventContactDetail
      .addCase(loadEventContactDetail.pending, (state): EventContactState => {
        return {
          ...state,
          detailEventContact: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadEventContactDetail.fulfilled, (state, action): EventContactState => {
        return {
          ...state,
          detailEventContact: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadEventContactDetail.rejected, (state, action): EventContactState => {
        return {
          ...state,
          detailEventContact: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadEventDeleteDelete
      .addCase(loadEventContactDelete.pending, (state): EventContactState => {
        return {
          ...state,
          deleteEventContact: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadEventContactDelete.fulfilled, (state, action): EventContactState => {
        return {
          ...state,
          deleteEventContact: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadEventContactDelete.rejected, (state, action): EventContactState => {
        return {
          ...state,
          deleteEventContact: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadEditEventContact
      .addCase(loadEditEventContact.pending, (state): EventContactState => {
        return {
          ...state,
          editEventContact: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadEditEventContact.fulfilled, (state, action): EventContactState => {
        return {
          ...state,
          editEventContact: {
            currentState: AsyncStateType.FULFILLED,
            // response: action.payload,
          },
        };
      })
      .addCase(loadEditEventContact.rejected, (state, action): EventContactState => {
        return {
          ...state,
          editEventContact: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
  },
});

// export the combined reducers
export const EventContactReducer = EventContactSlice.reducer;

// Other code such as selectors can use the imported `RootState` type
export const selectEventContact = (state: RootState) => state.eventContactReducer;
