import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "src/stores";
import { AsyncResponseState, AsyncStateType, Headers } from "src/stores/shares/utility";
import { ProfileUpdate1Response } from "./types";
import { envConfig } from "src/config/envConfig";
import { ProfileDetailType } from "../../profileDetail-kit/types";
const headers = Headers();

// Define a type for the slice state
export interface ProfileUpdate1State
  extends AsyncResponseState<AxiosResponse<ProfileUpdate1Response>> {}

// Define the initial state using that type
const initialState: ProfileUpdate1State = {
  currentState: AsyncStateType.INITIAL,
};

export const loadProfileUpdate1 = createAsyncThunk(
  "profileUpdate1/loadProfileUpdate1",
  async (args: ProfileDetailType, thunkAPI) => {
    return axios.post<ProfileUpdate1Response>(`${envConfig?.remoteApi}/home/proupdate1`, args, {
      headers,
    });
  }
);

export const ProfileUpdate1Slice = createSlice({
  name: "profileUpdate1",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadProfileUpdate1.pending, (state): ProfileUpdate1State => {
        return {
          ...state,
          currentState: AsyncStateType.PENDING,
        };
      })
      .addCase(loadProfileUpdate1.fulfilled, (state, action): ProfileUpdate1State => {
        return {
          ...state,
          currentState: AsyncStateType.FULFILLED,
          response: action.payload,
        };
      })
      .addCase(loadProfileUpdate1.rejected, (state, action): ProfileUpdate1State => {
        return {
          ...state,
          currentState: AsyncStateType.REJECTED,
          error: `error: ${action.error.code} ${action.error.message}`,
        };
      });
  },
});

// export the combined reducers
export const ProfileUpdate1Reducer = ProfileUpdate1Slice.reducer;

// Other code such as selectors can use the imported `RootState` type
export const selectProfileUpdate1 = (state: RootState) => state.profileUpdate1Reducer;
