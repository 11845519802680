import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
  signInWithEmailAndPassword,
  revokeAccessToken,
  createUserWithEmailAndPassword
} from "firebase/auth";
import { getDatabase } from "firebase/database";
import { envConfig } from "./envConfig";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

// Initialize Firebase Auth provider
const provider = new GoogleAuthProvider();

export const auth = getAuth();
export const revokeToken = (token: string) => revokeAccessToken(auth, token);
export const logOut = () => signOut(auth);
export const signInWithGooglePopup = () => signInWithPopup(auth, provider);
export const signInWithEmail = (email: string, pass:any) =>
  signInWithEmailAndPassword(
    auth,
    email,
    email === 'admin@kanhasoft.com' ? envConfig?.firebasePassword : pass
  );

export const createUserWithEmailPassword = (email: any, pass:any) =>
  createUserWithEmailAndPassword(
      auth,
      email,
      pass
    );

export const getDB = getDatabase();
