import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "src/stores";
import { AsyncResponseState, AsyncStateType, Headers } from "src/stores/shares/utility";
import { ProfileUpdate2Type, ProfileUpdate2Response } from "./types";
import { envConfig } from "src/config/envConfig";
const headers = Headers();

// Define a type for the slice state
export interface ProfileUpdate2State
  extends AsyncResponseState<AxiosResponse<ProfileUpdate2Response>> {}

// Define the initial state using that type
const initialState: ProfileUpdate2State = {
  currentState: AsyncStateType.INITIAL,
};

export const loadProfileUpdate2 = createAsyncThunk(
  "profileUpdate2/loadProfileUpdate2",
  async (args: ProfileUpdate2Type, thunkAPI) => {
    return axios.post<ProfileUpdate2Response>(`${envConfig?.remoteApi}/home/proupdate2`, args, {
      headers,
    });
  }
);

export const ProfileUpdate2Slice = createSlice({
  name: "profileUpdate2",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadProfileUpdate2.pending, (state): ProfileUpdate2State => {
        return {
          ...state,
          currentState: AsyncStateType.PENDING,
        };
      })
      .addCase(loadProfileUpdate2.fulfilled, (state, action): ProfileUpdate2State => {
        return {
          ...state,
          currentState: AsyncStateType.FULFILLED,
          response: action.payload,
        };
      })
      .addCase(loadProfileUpdate2.rejected, (state, action): ProfileUpdate2State => {
        return {
          ...state,
          currentState: AsyncStateType.REJECTED,
          error: `error: ${action.error.code} ${action.error.message}`,
        };
      });
  },
});

// export the combined reducers
export const ProfileUpdate2Reducer = ProfileUpdate2Slice.reducer;

// Other code such as selectors can use the imported `RootState` type
export const selectProfileUpdate2 = (state: RootState) => state.profileUpdate2Reducer;
