import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "src/stores";
import { AsyncResponseState, AsyncStateType, Headers } from "src/stores/shares/utility";
import { InterestedResponse } from "./types";
import { envConfig } from "src/config/envConfig";
const headers = Headers();

// Define a type for the slice state
export interface InterestedState {
  getInterested: AsyncResponseState<AxiosResponse<InterestedResponse>>;
  deleteInterested: AsyncResponseState<AxiosResponse<InterestedResponse>>;
}

// Define the initial state using that type
const initialState: InterestedState = {
  getInterested: {
    currentState: AsyncStateType.INITIAL,
  },
  deleteInterested: {
    currentState: AsyncStateType.INITIAL,
  },
};

export const loadInterested = createAsyncThunk(
  "interested/loadInterested",
  async (args, thunkAPI) => {
    return axios.post<InterestedResponse>(
      `${envConfig?.remoteApi}/admin2/intrestedUsers`,
      {
        length: 25,
        start: 0,
        draw: 1,
        column_name: "id",
        order: "desc",
        search: "",
      },
      { headers }
    );
  }
);

export const loadDeleteInterested = createAsyncThunk(
  "interested/loadDeleteInterested",
  async (args: number, thunkAPI) => {
    return axios.post<InterestedResponse>(
      `${envConfig?.remoteApi}/admin/deleteIntrested`,
      { id: args },
      { headers }
    );
  }
);

export const InterestedSlice = createSlice({
  name: "interested",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // loadInterested
      .addCase(loadInterested.pending, (state): InterestedState => {
        return {
          ...state,
          getInterested: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadInterested.fulfilled, (state, action): InterestedState => {
        return {
          ...state,
          getInterested: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadInterested.rejected, (state, action): InterestedState => {
        return {
          ...state,
          getInterested: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadDeleteInterested
      .addCase(loadDeleteInterested.pending, (state): InterestedState => {
        return {
          ...state,
          deleteInterested: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadDeleteInterested.fulfilled, (state, action): InterestedState => {
        return {
          ...state,
          deleteInterested: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadDeleteInterested.rejected, (state, action): InterestedState => {
        return {
          ...state,
          deleteInterested: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      });
  },
});

// export the combined reducers
export const InterestedReducer = InterestedSlice.reducer;

// Other code such as selectors can use the imported `RootState` type
export const selectInterested = (state: RootState) => state.interestedReducer;
