import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "src/stores";
import { AsyncResponseState, AsyncStateType, Headers } from "src/stores/shares/utility";
import {
  BlindDateDetailResponse,
  BlindDateListResponse,
  BlindDateListType,
  GetUsersListBlindDateResponse,
} from "./types";
import { envConfig } from "src/config/envConfig";
const headers = Headers();

// Define a type for the slice state
export interface BlindDateListState {
  getUsersListBlindDate: AsyncResponseState<AxiosResponse<GetUsersListBlindDateResponse>>;
  blindDateList: AsyncResponseState<AxiosResponse<BlindDateListResponse>>;
  addBlindDate: AsyncResponseState<AxiosResponse<BlindDateListResponse>>;
  getBlindDateDetail: AsyncResponseState<AxiosResponse<BlindDateDetailResponse>>;
  deleteBlindDate: AsyncResponseState<AxiosResponse<BlindDateDetailResponse>>;
}

// Define the initial state using that type
const initialState: BlindDateListState = {
  getUsersListBlindDate: {
    currentState: AsyncStateType.INITIAL,
  },
  blindDateList: {
    currentState: AsyncStateType.INITIAL,
  },
  addBlindDate: {
    currentState: AsyncStateType.INITIAL,
  },
  getBlindDateDetail: {
    currentState: AsyncStateType.INITIAL,
  },
  deleteBlindDate: {
    currentState: AsyncStateType.INITIAL,
  },
};

export const loadGetUsersListBlindDate = createAsyncThunk(
  "getUsersListBlindDate/loadGetUsersListBlindDate",
  async (args, thunkAPI) => {
    return axios.post<GetUsersListBlindDateResponse>(
      `${envConfig?.remoteApi}/admin/getUsersListBlindDate`,
      {
        length: 25,
        start: 0,
        draw: 1,
        column_name: "user_id",
        order: "asc",
        search: "",
        selectedType: "November",
        plan: "",
      },
      { headers }
    );
  }
);

export const loadBlindDateList = createAsyncThunk(
  "blindDateList/loadBlindDateList",
  async (args, thunkAPI) => {
    return axios.post<BlindDateListResponse>(
      `${envConfig?.remoteApi}/admin/BlindDateList`,
      {
        length: 25,
        start: 0,
        draw: 1,
        column_name: "Username",
        order: "desc",
        search: "",
      },
      { headers }
    );
  }
);

export const loadAddBlindDate = createAsyncThunk(
  "addBlindDate/loadAddBlindDate",
  async (args: BlindDateListType, thunkAPI) => {
    return axios.post<BlindDateListResponse>(`${envConfig?.remoteApi}/admin/addBlindDate`, args, {
      headers,
    });
  }
);

export const loadGetBlindDateDetail = createAsyncThunk(
  "getBlindDateDetail/loadGetBlindDateDetail",
  async (userId: string, thunkAPI) => {
    return axios.post<BlindDateDetailResponse>(
      `${envConfig?.remoteApi}/admin/getBlindDateDetails`,
      { id: userId },
      {
        headers,
      }
    );
  }
);

export const loadDeleteBlindDate = createAsyncThunk(
  "deleteBlindDate/loadDeleteBlindDate",
  async (userId: number, thunkAPI) => {
    return axios.post<BlindDateDetailResponse>(
      `${envConfig?.remoteApi}/admin/deleteBlindDate`,
      { id: userId },
      {
        headers,
      }
    );
  }
);

export const BlindDateListSlice = createSlice({
  name: "blindDateList",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // getUsersList
      .addCase(loadGetUsersListBlindDate.pending, (state): BlindDateListState => {
        return {
          ...state,
          getUsersListBlindDate: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadGetUsersListBlindDate.fulfilled, (state, action): BlindDateListState => {
        return {
          ...state,
          getUsersListBlindDate: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadGetUsersListBlindDate.rejected, (state, action): BlindDateListState => {
        return {
          ...state,
          getUsersListBlindDate: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      //  BlindDateList
      .addCase(loadBlindDateList.pending, (state): BlindDateListState => {
        return {
          ...state,
          blindDateList: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadBlindDateList.fulfilled, (state, action): BlindDateListState => {
        return {
          ...state,
          blindDateList: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadBlindDateList.rejected, (state, action): BlindDateListState => {
        return {
          ...state,
          blindDateList: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadAddBlindDate
      .addCase(loadAddBlindDate.pending, (state): BlindDateListState => {
        return {
          ...state,
          addBlindDate: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadAddBlindDate.fulfilled, (state, action): BlindDateListState => {
        return {
          ...state,
          addBlindDate: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadAddBlindDate.rejected, (state, action): BlindDateListState => {
        return {
          ...state,
          addBlindDate: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadGetBlindDateDetail
      .addCase(loadGetBlindDateDetail.pending, (state): BlindDateListState => {
        return {
          ...state,
          getBlindDateDetail: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadGetBlindDateDetail.fulfilled, (state, action): BlindDateListState => {
        return {
          ...state,
          getBlindDateDetail: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadGetBlindDateDetail.rejected, (state, action): BlindDateListState => {
        return {
          ...state,
          getBlindDateDetail: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadDeleteBlindDate
      .addCase(loadDeleteBlindDate.pending, (state): BlindDateListState => {
        return {
          ...state,
          deleteBlindDate: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadDeleteBlindDate.fulfilled, (state, action): BlindDateListState => {
        return {
          ...state,
          deleteBlindDate: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadDeleteBlindDate.rejected, (state, action): BlindDateListState => {
        return {
          ...state,
          deleteBlindDate: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      });
  },
});

// export the combined reducers
export const BlindDateListReducer = BlindDateListSlice.reducer;

// Other code such as selectors can use the imported `RootState` type
export const selectBlindDateList = (state: RootState) => state.blindDateListReducer;
