import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "src/stores";
import { AsyncResponseState, AsyncStateType, Headers } from "src/stores/shares/utility";
import { UserArgs, UserCountResponse } from "./types";
import { envConfig } from "src/config/envConfig";
const headers = Headers();

// Define a type for the slice state
export interface UserCountState extends AsyncResponseState<AxiosResponse<UserCountResponse>> {}

// Define the initial state using that type
const initialState: UserCountState = {
  currentState: AsyncStateType.INITIAL,
};

export const loadUserCount = createAsyncThunk(
  "userCount/loadUserCount",
  async (args: UserArgs, thunkAPI) => {
    return axios.get<UserCountResponse>(
      `${envConfig?.remoteApi}/admin/userscount?matchmaker_id=${args?.matchmaker_id}&role_id=${args?.role_id}`,
      { headers }
    );
  }
);

export const loadGetUpcomigEvents = createAsyncThunk(
  "getUpcomigEvents/loadGetUpcomigEvents",
  async (args, thunkAPI) => {
    return axios.get<UserCountResponse>(
      `${envConfig?.remoteApi}/admin/getUpcomingEvents`,
      { headers }
    );
  }
);

export const loadGetUpcomigSuggestions = createAsyncThunk(
  "getUpcomigSuggestions/loadGetUpcomigSuggestions",
  async (args, thunkAPI) => {
    return axios.get<UserCountResponse>(
      `${envConfig?.remoteApi}/admin/getUpcomingEventSuggestion`,
      { headers }
    );
  }
);

export const loadGetParticipant = createAsyncThunk(
  "getParticipant/loadGetParticipant",
  async (args, thunkAPI) => {
    return axios.get<UserCountResponse>(
      `${envConfig?.remoteApi}/admin/ticketParticipantList`,
      { headers }
    );
  }
);

export const loadGetLikes = createAsyncThunk(
  "getLikes/loadGetLikes",
  async (args, thunkAPI) => {
    return axios.get<UserCountResponse>(
      `${envConfig?.remoteApi}/admin/eventLikeList`,
      { headers }
    );
  }
);

export const loadGetCalendarData = createAsyncThunk(
  "getCalendarData/loadGetCalendarData",
  async (args, thunkAPI) => {
    return axios.get<UserCountResponse>(
      `${envConfig?.remoteApi}/admin/calendarDataList`,
      { headers }
    );
  }
);


export const UserCountSlice = createSlice({
  name: "userCount",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadUserCount.pending, (state): UserCountState => {
        return {
          ...state,
          currentState: AsyncStateType.PENDING,
        };
      })
      .addCase(loadUserCount.fulfilled, (state, action): UserCountState => {
        return {
          ...state,
          currentState: AsyncStateType.FULFILLED,
          response: action.payload,
        };
      })
      .addCase(loadUserCount.rejected, (state, action): UserCountState => {
        return {
          ...state,
          currentState: AsyncStateType.REJECTED,
          error: `error: ${action.error.code} ${action.error.message}`,
        };
      })
      // loadGetUpcomigEvents
      .addCase(loadGetUpcomigEvents.pending, (state): UserCountState => {
        return {
          ...state,
          currentState: AsyncStateType.PENDING,
        };
      })
      .addCase(loadGetUpcomigEvents.fulfilled, (state, action): UserCountState => {
        return {
          ...state,
          currentState: AsyncStateType.FULFILLED,
          response: action.payload,
        };
      })
      .addCase(loadGetUpcomigEvents.rejected, (state, action): UserCountState => {
        return {
          ...state,
          currentState: AsyncStateType.REJECTED,
          error: `error: ${action.error.code} ${action.error.message}`,
        };
      })
      // loadGetUpcomigSuggestions
      .addCase(loadGetUpcomigSuggestions.pending, (state): UserCountState => {
        return {
          ...state,
          currentState: AsyncStateType.PENDING,
        };
      })
      .addCase(loadGetUpcomigSuggestions.fulfilled, (state, action): UserCountState => {
        return {
          ...state,
          currentState: AsyncStateType.FULFILLED,
          response: action.payload,
        };
      })
      .addCase(loadGetUpcomigSuggestions.rejected, (state, action): UserCountState => {
        return {
          ...state,
          currentState: AsyncStateType.REJECTED,
          error: `error: ${action.error.code} ${action.error.message}`,
        };
      })
      // loadGetParticipant
      .addCase(loadGetParticipant.pending, (state): UserCountState => {
        return {
          ...state,
          currentState: AsyncStateType.PENDING,
        };
      })
      .addCase(loadGetParticipant.fulfilled, (state, action): UserCountState => {
        return {
          ...state,
          currentState: AsyncStateType.FULFILLED,
          response: action.payload,
        };
      })
      .addCase(loadGetParticipant.rejected, (state, action): UserCountState => {
        return {
          ...state,
          currentState: AsyncStateType.REJECTED,
          error: `error: ${action.error.code} ${action.error.message}`,
        };
      })
      // loadLikes
      .addCase(loadGetLikes.pending, (state): UserCountState => {
        return {
          ...state,
          currentState: AsyncStateType.PENDING,
        };
      })
      .addCase(loadGetLikes.fulfilled, (state, action): UserCountState => {
        return {
          ...state,
          currentState: AsyncStateType.FULFILLED,
          response: action.payload,
        };
      })
      .addCase(loadGetLikes.rejected, (state, action): UserCountState => {
        return {
          ...state,
          currentState: AsyncStateType.REJECTED,
          error: `error: ${action.error.code} ${action.error.message}`,
        };
      })
      // loadGetCalendarData
      .addCase(loadGetCalendarData.pending, (state): UserCountState => {
        return {
          ...state,
          currentState: AsyncStateType.PENDING,
        };
      })
      .addCase(loadGetCalendarData.fulfilled, (state, action): UserCountState => {
        return {
          ...state,
          currentState: AsyncStateType.FULFILLED,
          response: action.payload,
        };
      })
      .addCase(loadGetCalendarData.rejected, (state, action): UserCountState => {
        return {
          ...state,
          currentState: AsyncStateType.REJECTED,
          error: `error: ${action.error.code} ${action.error.message}`,
        };
      })
  },
});

// export the combined reducers
export const UserCountReducer = UserCountSlice.reducer;

// Other code such as selectors can use the imported `RootState` type
export const selectUserCount = (state: RootState) => state.userCountReducer;
