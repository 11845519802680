import { makeStyles } from "@mui/styles";

export const alertStyle = makeStyles({
  notificationBox: {
    zIndex: "1 !important",
    "& .MuiBackdrop-root": { position: "inherit", opacity: "0 !important" },
    "& .MuiDialog-container": {
      position: "fixed",
      top: "32px",
      right: 0,
      minWidth: "450px",
      alignItems: "start",
    },
  },
  notificationActionBox: {
    backgroundColor: "#e9eaed",
    fontWeight: "bold",
    borderTop: "1px solid #dddddd",
  },
  sweetAlert: {
    animation: "showSweetAlert 0.2s",
  },
  sweetHideAlert: {
    animation: "hideSweetAlert 0.2s",
  },
  fadeDialog: {
    transition: "opacity .15s linear",
  },
});
