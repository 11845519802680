import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "src/stores";
import { AsyncResponseState, AsyncStateType } from "src/stores/shares/utility";
import { NotificationPayload, NotificationResponse, ReadNotificationType } from "./types";
import { envConfig } from "src/config/envConfig";

// Define a type for the slice state
export interface NotificationState {
  notificationAll: AsyncResponseState<AxiosResponse<NotificationResponse>>;
  readAllNotification: AsyncResponseState<AxiosResponse<ReadNotificationType>>;
}

// Define the initial state using that type
const initialState: NotificationState = {
  notificationAll: {
    currentState: AsyncStateType.INITIAL,
  },
  readAllNotification: {
    currentState: AsyncStateType.INITIAL,
  },
};

export const loadNotification = createAsyncThunk(
  "NotificationUser/loadNotification",
  async (args: NotificationPayload, thunkAPI) => {
    return axios.post<NotificationResponse>(
      `${envConfig?.remoteApi}/admin/GetNotificaionList`,
      args
    );
  }
);

export const loadReadAllNotification = createAsyncThunk(
  "NotificationUser/loadReadAllNotification",
  async (args: string, thunkAPI) => {
    return axios.post<ReadNotificationType>(
      `${envConfig?.remoteApi}/home/readAllAdminNotification`,
      { id: args }
    );
  }
);

export const NotificationSlice = createSlice({
  name: "NotificationUser",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // loadNotification
      .addCase(loadNotification.pending, (state): NotificationState => {
        return {
          ...state,
          notificationAll: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadNotification.fulfilled, (state, action): NotificationState => {
        return {
          ...state,
          notificationAll: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadNotification.rejected, (state, action): NotificationState => {
        return {
          ...state,
          notificationAll: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadReadAllNotification
      .addCase(loadReadAllNotification.pending, (state): NotificationState => {
        return {
          ...state,
          readAllNotification: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadReadAllNotification.fulfilled, (state, action): NotificationState => {
        return {
          ...state,
          readAllNotification: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadReadAllNotification.rejected, (state, action): NotificationState => {
        return {
          ...state,
          readAllNotification: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      });
  },
});

// export the combined reducers
export const NotificationReducer = NotificationSlice.reducer;

// Other code such as selectors can use the imported `RootState` type
export const selectNotification = (state: RootState) => state.notificationReducer;
