import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "src/stores";
import { AsyncResponseState, AsyncStateType, Headers } from "src/stores/shares/utility";
import { WaitingListResponse, waitingListSearch } from "./types";
import { envConfig } from "src/config/envConfig";
const headers = Headers();

// Define a type for the slice state
export interface WaitingListState extends AsyncResponseState<AxiosResponse<WaitingListResponse>> {}

// Define the initial state using that type
const initialState: WaitingListState = {
  currentState: AsyncStateType.INITIAL,
};

export const loadWaitingList = createAsyncThunk(
  "waitingList/loadWaitingList",
  async (args: waitingListSearch, thunkAPI) => {
    return axios.post<WaitingListResponse>(
      `${envConfig?.remoteApi}/admin/WaitingUsersList`,
      {
        column_name: "user_id",
        draw: 1,
        length: 25,
        order: "asc",
        plan: args?.plan,
        search: "",
        selectedType: args?.selectedType,
        start: 0,
      },
      { headers }
    );
  }
);

export const WaitingListSlice = createSlice({
  name: "waitingList",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadWaitingList.pending, (state): WaitingListState => {
        return {
          ...state,
          currentState: AsyncStateType.PENDING,
        };
      })
      .addCase(loadWaitingList.fulfilled, (state, action): WaitingListState => {
        return {
          ...state,
          currentState: AsyncStateType.FULFILLED,
          response: action.payload,
        };
      })
      .addCase(loadWaitingList.rejected, (state, action): WaitingListState => {
        return {
          ...state,
          currentState: AsyncStateType.REJECTED,
          error: `error: ${action.error.code} ${action.error.message}`,
        };
      });
  },
});

// export the combined reducers
export const WaitingListReducer = WaitingListSlice.reducer;

// Other code such as selectors can use the imported `RootState` type
export const selectWaitingList = (state: RootState) => state.waitingListReducer;
