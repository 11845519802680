import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "src/stores";
import { AsyncResponseState, AsyncStateType, Headers } from "src/stores/shares/utility";
import { EmailCheckResponse, UserRegistrationResponse, UserRegistrationType } from "./types";
import { envConfig } from "src/config/envConfig";
const headers = Headers();

// Define a type for the slice state
export interface UserRegistrationState {
  userRegistration: AsyncResponseState<AxiosResponse<UserRegistrationResponse>>;
  emailCheck: AsyncResponseState<AxiosResponse<EmailCheckResponse>>;
}

// Define the initial state using that type
const initialState: UserRegistrationState = {
  userRegistration: {
    currentState: AsyncStateType.INITIAL,
  },
  emailCheck: {
    currentState: AsyncStateType.INITIAL,
  },
};

export const loadUserRegistration = createAsyncThunk(
  "UserRegistration/loadUserRegistration",
  async (args: UserRegistrationType, thunkAPI) => {
    return axios.post<UserRegistrationResponse>(
      `${envConfig?.remoteApi}/useraccess/RegisteringBackend`,
      args,
      { headers }
    );
  }
);

export const loadEmailCheck = createAsyncThunk(
  "UserRegistration/loadEmailCheck",
  async (args: string, thunkAPI) => {
    return axios.post<EmailCheckResponse>(
      `${envConfig?.remoteApi}/general/CheckRegisterEmails`,
      { email: args },
      { headers }
    );
  }
);

export const UserRegistrationSlice = createSlice({
  name: "UserRegistration",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // loadUserRegistration
      .addCase(loadUserRegistration.pending, (state): UserRegistrationState => {
        return {
          ...state,
          userRegistration: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadUserRegistration.fulfilled, (state, action): UserRegistrationState => {
        return {
          ...state,
          userRegistration: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadUserRegistration.rejected, (state, action): UserRegistrationState => {
        return {
          ...state,
          userRegistration: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      })
      // loadEmailCheck
      .addCase(loadEmailCheck.pending, (state): UserRegistrationState => {
        return {
          ...state,
          emailCheck: {
            currentState: AsyncStateType.PENDING,
          },
        };
      })
      .addCase(loadEmailCheck.fulfilled, (state, action): UserRegistrationState => {
        return {
          ...state,
          emailCheck: {
            currentState: AsyncStateType.FULFILLED,
            response: action.payload,
          },
        };
      })
      .addCase(loadEmailCheck.rejected, (state, action): UserRegistrationState => {
        return {
          ...state,
          emailCheck: {
            currentState: AsyncStateType.REJECTED,
            error: `error: ${action.error.code} ${action.error.message}`,
          },
        };
      });
  },
});

// export the combined reducers
export const UserRegistrationReducer = UserRegistrationSlice.reducer;

// Other code such as selectors can use the imported `RootState` type
export const selectUserRegistration = (state: RootState) => state.userRegistrationReducer;
